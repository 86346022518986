import React from "react";
import ViewButton from '../component/View_Arrow'

const DetailExpand = ({ HeadingDetail, DetailText, hoverHeight, mobHeight }) => {
    return (
        <React.Fragment>
            <div className="group">
                <div className={`border-[0.5px] border-[#D9D9D9] rounded-md px-6 pt-4 pb-4 group-hover:pb-0 bg-white lg:h-28 ${mobHeight} group-hover:h-fit`}>
                    <p className='text-[15px] font-medium'>{HeadingDetail}</p>
                    <div className=''>
                        <p className={`text-xs h-0 group-hover:h-auto overflow-hidden mt-2 transition-all duration-300 ease-in`}>{DetailText}</p>
                        <ViewButton
                            className={'mt-0 lg:flex hidden group-hover:hidden'}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default DetailExpand