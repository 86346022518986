import React from 'react';
import { PATH } from '../globals/app-constants';


const Fundamentals = () => {
    return (
        <div className="relative overflow-x-auto lg:px-20 px-5 py-24 bg-[#EFEFEF]">
            <div className='container mx-auto'>
                <div className='flex flex-col justify-center gap-2'>
                    <p className='text-base text-center'>Witness Our Client Testimonies</p>
                    <h6 className='text-[42px] flex justify-center items-center'><div className='w-20 border-t-[1px] border-black'></div><span className='lg:px-5 px-2 banner_head text-center'>Happy Faces</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                    <p className='text-base text-center'>Explore what our customers, from startups to global enterprises, has to say about Live Agent.</p>
                </div>
                <div className='grid md:grid-cols-2 gap-5 md:px-10 mt-12 relative'>
                    <div className='border-[0.5px] border-[#D9D9D9] p-8 relative'>
                        <p className='text-base'>Big advantage of working together with Live Agent, is that we are flexible in finding out the possible way of achieving the goals. They make sure that I make it clear on my end on what I need time to time.</p>
                        <div className='border-t-[0.5px] border-[#D9D9D9] my-4'></div>
                        <div className='flex items-center'>
                            <div className='rounded-full w-14 h-14'>
                                <img src={PATH.img.Profile} className='w-full h-full object-cover rounded-full' alt='profile' />
                            </div>
                            <p className='text-2xl font-medium ps-3'>Steffi</p>
                        </div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-7 h-7 absolute left-0 top-0 bottom-0 my-auto hidden lg:block">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-7 h-7 absolute right-0 top-0 bottom-0 my-auto hidden lg:block">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                    </svg>
                    <div className='border-[0.5px] border-[#D9D9D9] p-8'>
                        <p className='text-base'>A fantastic organisation! Great customer support from beginning to end of the process. The team are really informed and go the extra mile at every stage. I would recommend them unreservedly.</p>
                        <div className='border-t-[0.5px] border-[#D9D9D9] my-4'></div>
                        <div className='flex items-center'>
                            <div className='rounded-full w-14 h-14'>
                                <img src={PATH.img.Profile2} className='w-full h-full object-cover rounded-full' alt='profile' />
                            </div>
                            <p className='text-2xl font-medium ps-3'>Immaculate</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Fundamentals;