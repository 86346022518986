import React from 'react';
import { PATH } from '../globals/app-constants';

const Button = ({ onClick, className }) => {
    return (
        <button onClick={onClick}>
            <div className={`inline-flex items-center cursor-pointer ${className}`}>
                <div className='bg-black rounded-full flex items-center justify-center w-8 h-8'>
                    <img src={PATH.img.Arrow} width={6} alt='arrow' />
                </div>
                <p className='text-base font-medium ps-2'>View More</p>
            </div>
        </button>
    );
};

export default Button;
