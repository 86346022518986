import React from "react";
import { PATH } from "../../../globals/app-constants";
import DetailExpand from "../../../component/Detail_Expand";
import Fundamentals from "../../../component/FundamentalsContent";
import RequestDemo from '../../../component/RequestDemo';
import Testimonial from '../../../component/Testimonial';
import Footer from '../../../component/HOC/Footer';
import DetailUpcoming from '../../../component/Detail_Upcoming';

const SmallLanguage = () => {
    document.body.style.overflow = 'auto';
    return (
        <React.Fragment>
            <div className='lg:px-20 px-5 py-16'>
                <div className="container mx-auto">
                    <div className='flex lg:flex-row flex-col-reverse items-center mt-20'>
                        <div className='lg:w-2/5 lg:mt-0 mt-7'>
                            <img src={PATH.img.LANG_IMG} alt='features-img' />
                        </div>
                        <div className='lg:w-3/5 lg:ps-7'>
                            <div className='flex flex-col gap-3'>
                                <p className='text-base'>Seamless Multilingual Communication</p>
                                <h6 className='text-[55px] flex items-center'>
                                    <span className='lg:pe-5 pe-2'>Small Language Model</span>
                                    <div className='w-20 border-t-[1px] border-black'></div>
                                </h6>
                                <p className='text-[22px]'>Breaking Language Barriers for Engaged Interactions</p>
                                <p className='mt-2 text-base'>CVI's innovative technology removes language barriers and personalizes the shopping experience for every customer. Transforming vendor-customer connections, our Small Language Module facilitates seamless interactions via HelpNow's Live Agent. It interprets diverse language commands from customers, and presents tailored product options in their language, ensuring a personalized shopping odyssey.</p>
                                <p className='mt-2 text-base'>Discover the CVI Small Language Module today – Connect with one of our Agents for a Quick Demo.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:px-20 px-5 py-3 overflow-hidden relative">
                <div className='absolute lg:block hidden bottom-[-20px] border-gray-200 right-[-130px] top-0 w-60 h-60 border-[1px] rounded-full'></div>
                <div className='absolute lg:block hidden bottom-t-0 border-gray-200 right-60 top-0 w-80 border-[0.5px] rounded-full'></div>
                <div className="container mx-auto relative py-5 lg:py-20">
                    <div className='flex flex-col justify-center gap-4'>
                        <h6 className='text-[32px] flex justify-center items-center text-center'>
                            <div className='w-20 border-t-[1px] border-black'></div>
                            <span className='lg:px-5 px-2'>Bridge the Gap, Boost the Business</span>
                            <div className='w-20 border-t-[1px] border-black'></div>
                        </h6>
                        <p className='text-[22px] text-center'>Personalized Support-Guaranteed Satisfaction-Customer Success</p>
                        <p className="text-base max-w-3xl mx-auto text-center mt-3">Amid diverse markets, language barriers shouldn't stifle customer needs; our solution bridges linguistic divides. With CVI's Small Language Module, connect across borders, personalize the journey, and watch your brand resonate in every language. Translate confusion into confidence, whispers into sales – unlock the global symphony of your success</p>
                    </div>
                </div>
            </div>
            <div className='bg-[#EFEFEF] px-5 lg:px-20 py-16'>
                <div className='container mx-auto lg:flex gap-y-9 items-start'>
                    <Fundamentals
                        ImgHidden={'lg:block hidden'}
                        DividerImg={PATH.img.LineBlack}
                        Icon={PATH.img.ArIcon1}
                        className={'text-black'}
                        HeadingText={'Simplified Interactions'}
                        ParaText={'Analyzes languages, displays relevant products, ensuring personalized shopping, overcoming barriers, enhancing satisfaction.'}
                    />
                    <Fundamentals
                        ImgHidden={'lg:block hidden'}
                        DividerImg={PATH.img.LineBlack}
                        Icon={PATH.img.ArIcon2}
                        className={'text-black'}
                        HeadingText={'Seamless Integration'}
                        ParaText={'Effortlessly integrates via API for seamless functionality within existing infrastructure and platforms.'}
                    />
                    <Fundamentals
                        ImgHidden={'hidden'}
                        DividerImg={PATH.img.LineBlack}
                        Icon={PATH.img.ArIcon3}
                        className={'text-black'}
                        HeadingText={'Constant Learning'}
                        ParaText={`Evolving with every interaction, always speaking your customer's language.`}
                    />
                </div>
            </div>
            <div className="lg:px-20 px-5 relative overflow-hidden">
                <div className="container mx-auto py-20 lg:flex items-center ">
                    <div className="lg:w-1/2">
                        <div className="py-10 lg:pe-10 relative">
                            <img src={PATH.img.AboutDesign} className="absolute top-0 right-36 w-44" alt="live-agent" />
                            <video loop muted playsInline autoPlay className="relative z-10 w-full lg:h-full rounded-md object-cover">
                                <source src={PATH.video.LiveAgentVideo} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                    <div className='lg:w-1/2 flex flex-col gap-3 lg:ps-8 z-20'>
                        <div className='absolute lg:block hidden bottom-[-20px] border-gray-200 right-[-110px] top-16 w-60 h-60 z-10 border-[1px] rounded-full'></div>
                        <div className='absolute lg:block hidden bottom-t-0 border-gray-200 right-60 top-0 w-80 border-[0.5px] rounded-full rotate-90'></div>
                        <div className='absolute lg:block hidden bottom-t-0 border-gray-200 right-[27rem] bottom-0 w-80 border-[0.5px] rounded-full rotate-90'></div>
                        <p className='text-base'>Empowering understanding, ensuring voices are heard and valued.</p>
                        <p className='text-[22px]'>Discover the revolutionary language module</p>
                        <h6 className='text-[52px] flex items-center'><span className='lg:pe-5 pe-2'>Core Features</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                        <div className="lg:ps-16 mt-5 grid gap-2">
                            <p className='text-base flex gap-4'><span>-</span>
                                <span>Seamlessly translate customer queries and commands in real-time, bridging the communication gap instantly.</span>
                            </p>
                            <p className='text-base flex gap-4'><span>-</span>
                                <span>Cater to a diverse customer base with an extensive range of supported languages.</span>
                            </p>
                            <p className="text-base flex gap-4"><span>-</span>
                                <span>Go beyond literal translation and understand the intent behind customer requests for accurate product recommendations.</span>
                            </p>
                            <p className="text-base flex gap-4"><span>-</span>
                                <span>Continuously improve translation accuracy and relevance thanks to our ongoing AI learning process.</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:px-20 px-5 py-3 lg:h-[550px] bg-[#EFEFEF]">
                {/* <div className='absolute lg:block hidden bottom-[-20px] border-gray-200 right-[-130px] top-0 w-60 h-60 border-[1px] rounded-full'></div> */}
                {/* <div className='absolute lg:block hidden bottom-t-0 border-gray-200 right-60 top-0 w-80 border-[0.5px] rounded-full'></div> */}
                <div className="container mx-auto relative py-20">
                    <div className='flex flex-col justify-center gap-4'>
                        <h6 className='text-[32px] flex justify-center items-center'>
                            <div className='w-20 border-t-[1px] border-black'></div><span className='lg:px-5 px-2'>Transformative Benefits</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                        <p className='text-[22px] text-center'>Maximize Your Multilingual Potential</p>
                        <p className="text-base max-w-3xl mx-auto text-center mt-3">Our core benefits reshape the customer journey, enhancing engagement through personalized interactions and broadening accessibility by dismantling language barriers. Tailoring experiences to preferred languages elevates satisfaction, while seamless communication streamlines transactions, ensuring a seamless and satisfying customer experience.</p>
                    </div>
                    <div className='grid md:grid-cols-4 gap-3 mt-10'>
                        <DetailExpand
                            mobHeight={'h-14'}
                            hoverHeight={'h-16'}
                            HeadingDetail={'Data-Driven Insights'}
                            DetailText={'Customers easily access product details for informed purchases.'}
                        />
                        <DetailExpand
                            mobHeight={'h-14'}
                            hoverHeight={'h-16'}
                            HeadingDetail={'Cultural Sensitivity'}
                            DetailText={'Live agents engage customers instantly via chat, email, or video calls.'}
                        />
                        <DetailExpand
                            mobHeight={'h-14'}
                            hoverHeight={'h-16'}
                            HeadingDetail={'Brand Differentiation '}
                            DetailText={'Agents tailor responses to meet individual customer needs and preferences.'}
                        />
                        <DetailExpand
                            mobHeight={'h-14'}
                            hoverHeight={'h-16'}
                            HeadingDetail={'Global Market Penetration'}
                            DetailText={'Agents are trained to troubleshoot, answer questions, and resolve problems effectively.'}
                        />
                    </div>
                </div>
            </div>
            <div className="">
                <div className="container mx-auto lg:px-20 px-5 py-20 lg:flex items-start">
                    <div className="lg:w-1/2">
                        <div className="flex flex-col gap-3">
                            <p className='text-base'>Integration for Enhanced Platform Functionality and Harmony</p>
                            <p className='text-lg'>Streamlined Integration Success</p>
                            <h6 className='text-[52px] flex items-center'>
                                <span className='lg:pe-5 pe-2'>The Methodology</span>
                                <div className='w-20 border-t-[1px] border-black'></div>
                            </h6>
                            <img src={PATH.img.ComingSoonImg} className="p-10" alt="ComingSoon" />
                        </div>
                    </div>
                    <div className='lg:w-1/2 flex flex-col ps-8'>
                        <DetailUpcoming
                            HeadingDetail={'API-Based Connectivity'}
                            DetailText={`This process involves utilizing Application Programming Interface (API) integration methods to establish a seamless connection between the Small Language Module and existing platforms. Through API-based connectivity, the module can access and exchange information without disrupting the current system's operations. `}
                        />
                        <DetailUpcoming
                            HeadingDetail={'Harmonious Infrastructure Fusion'}
                            DetailText={`It involves a meticulous integration strategy, where the module's components are strategically integrated with the existing architecture. This fusion aims to enhance the overall functionality and coherence of the system, ensuring that the module works in tandem with other elements, leveraging existing resources efficiently, and minimizing conflicts or performance issues.`}
                        />
                        <DetailUpcoming
                            HeadingDetail={'Optimized Incorporation'}
                            DetailText={`This step involves detailed planning, analysis, and adaptation to ensure that the module's implementation aligns perfectly with the unique functionalities of the current systems. It encompasses compatibility checks, data mapping, and configuration adjustments to seamlessly integrate the module without compromising the stability or performance of the existing setup.`}
                        />
                        <DetailUpcoming
                            HeadingDetail={'Functional Enhancement'}
                            DetailText={`The integration aims not only to embed the module but also to enhance the overall functionality of the system. By effectively embedding the Small Language Module, it contributes to an enriched operational environment. It involves comprehensive testing, validation, and refinement to ensure that the system functions optimally, delivering improved performance and user experiences across various language contexts.`}
                        />
                        <DetailUpcoming
                            HeadingDetail={'Continuous Optimization'}
                            DetailText={`Post-incorporation, our process doesn't stop; it evolves. We continually refine and optimize the integrated module, ensuring it remains aligned with your systems. This ongoing improvement loop guarantees sustained efficiency and adaptability, ensuring your operations continually benefit from the latest enhancements and technological advancements.`} />
                    </div>
                </div>
            </div>
            <div className='bg-[#EFEFEF] px-5 lg:px-20 py-20'>
                <div className='container mx-auto lg:flex gap-y-9 items-start'>
                    <Fundamentals
                        ImgHidden={'lg:block hidden'}
                        DividerImg={PATH.img.LineBlack}
                        Icon={PATH.img.ArIcon1}
                        className={'text-black'}
                        HeadingText={'Adaptability'}
                        ParaText={'Tailoring the module to diverse linguistic landscapes while ensuring seamless integration and operational flexibility, adapting swiftly to evolving language patterns and nuances.'}
                    />
                    <Fundamentals
                        ImgHidden={'lg:block hidden'}
                        DividerImg={PATH.img.LineBlack}
                        Icon={PATH.img.ArIcon2}
                        className={'text-black'}
                        HeadingText={'Precision'}
                        ParaText={`Delivering accurate language interpretations and relevant product suggestions for enhanced user engagement and satisfaction, ensuring precise comprehension of varying customer queries.`} />
                    <Fundamentals
                        ImgHidden={'hidden'}
                        DividerImg={PATH.img.LineBlack}
                        Icon={PATH.img.ArIcon3}
                        className={'text-black'}
                        HeadingText={'Scalability'}
                        ParaText={'Facilitating effortless expansion across platforms and markets without compromising efficiency or performance, scaling seamlessly to meet evolving user demands and business requirements.'}
                    />
                </div>
            </div>
            <RequestDemo
                RequesDemoHeading={'Ready to experience the power of Live Agent for yourself?'}
                RequestDemoMainHead={'Get LIVE Demo'}
                RequestDemoPara={`See firsthand how Live Agent enhances real-time interactions, personalizes support, and offers a range of valuable features like cart generation and age verification. Take the next step in elevating your customer experience. Click 'Book a Demo Now' and embark on a journey to superior support and increased customer satisfaction.`} />
            <Testimonial />
            <Footer />
        </React.Fragment >
    );
};

export default SmallLanguage;