export const isValidEmail = (email) => {
    const validEmailRegex = RegExp(/^[a-zA-Z0-9+.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/);
    return validEmailRegex.test(email);
};

export const isFieldEmpty = (field) => {
    return (!field || field?.trim().length === 0);
};

export const validateMaxName = (name) => {
    let re = /^[a-zA-Z ]{4,150}$/;
    return re.test(String(name).toLowerCase());
};
export const isValidNumber = (field = '') => {
    // eslint-disable-next-line no-useless-escape
    var letters = /^[0-9]+$/;
    return letters.test(field);
};

export const validateMobileNumnber = (number) => {
    let re = /^(\+91[\-\s]?)?[0]?(91)?[789654321]\d{9}$/;
    return re.test(number);
};

export const Validation = (validationObj, fieldArray) => {
    let validationError = {};
    for (const [key, value] of Object.entries(validationObj)) {
        switch (key) {
            case fieldArray[0]:
                if (isFieldEmpty(value)) {
                    validationError[key] = "Please enter the name";
                    break;
                } else if (!validateMaxName(value)) {
                    validationError[key] = "Please enter the valid name";
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            case fieldArray[1]:
                if (isFieldEmpty(value)) {
                    validationError[key] = "Please enter the company name";
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            case fieldArray[2]:
                if (isFieldEmpty(value)) {
                    validationError[key] = "Please enter the phone number";
                    break;
                } else if (!validateMobileNumnber(value)) {
                    validationError[key] = "Please enter the valid phone number";
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            case fieldArray[3]:
                if (isFieldEmpty(value)) {
                    validationError[key] = "Please enter the email address";
                    break;
                } else if (!isValidEmail(value)) {
                    validationError[key] = "Please enter the valid email address";
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            case fieldArray[4]:
                if (isFieldEmpty(value)) {
                    validationError[key] = "Please enter the Message";
                    break;
                } else {
                    validationError[key] = '';
                    break;
                }
            default:
                break;
        }
    }
    return validationError;
};