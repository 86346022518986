import React from "react";
import { PATH } from "../../globals/app-constants";
import PricingContent from '../../component/Pricing';
import Testimonial from '../../component/Testimonial';
import Footer from '../../component/HOC/Footer';

const Pricing = () => {
    document.body.style.overflow = 'auto';
    return (
        <React.Fragment>
            <div className='lg:px-20 px-5 lg:py-20 py-8'>
                <div className='container mx-auto flex lg:flex-row flex-col-reverse items-center mt-5'>
                    <div className='lg:w-[28%] lg:mt-0 mt-7 relative h-full'>
                        <p className="text-[22px]">Behind the Brand:</p>
                        <p className="text-[22px]">A Tale of Excellence.</p>
                        <img src={PATH.img.AboutDesign} className="w-52 absolute right-16 bottom-[-70px]" alt='features-img' />
                    </div>
                    <div className='lg:w-[72%] lg:ps-7 lg:mt-0 mt-9'>
                        <div className='flex flex-col gap-3'>
                            <p className='text-base'>Behind the Brand: A Tale of Excellence.</p>
                            <h6 className='text-[55px] flex items-center lg:mt-6 mt-2'><span className='lg:pe-5 pe-2'>Pricing</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                            <p className='text-base'>We are entering a new world of “Mixed Reality “– Live Commerce and Augmented reality will be intertwined to provide in-store experience sitting from home.</p>
                            <p className='mt-2 text-base'>Computer Vision is going to be cornerstone of Digital Transformation –ability to do more with less resources. Our vision is to Bridge E-Commerce and Physical Store with a digital Experience.</p>
                            <p className='mt-2 text-base'>High customer confidence results in a high-quality, low-regret purchase. Make the Retail Store 24/7 Accessible through their e-commerce. Combine Product with their Real-Life surrounding. Enable Live Commerce + Augmented reality.</p>
                        </div>
                    </div>
                </div>
            </div>
            <PricingContent />
            <Testimonial />
            <Footer />
        </React.Fragment >
    );
};

export default Pricing;