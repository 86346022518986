import React from "react";
import { PATH } from "../../../globals/app-constants";
import DetailExpand from "../../../component/Detail_Expand";
import RequestDemo from '../../../component/RequestDemo';
import Testimonial from '../../../component/Testimonial';
import Footer from '../../../component/HOC/Footer';

const CartGeneration = () => {
    document.body.style.overflow = 'auto';
    return (
        <React.Fragment>
            <div className='lg:px-20 px-5 py-16'>
                <div className="container mx-auto">
                    <div className='flex lg:flex-row flex-col-reverse items-center mt-20'>
                        <div className='lg:w-2/5 lg:mt-0 mt-7'>
                            <img src={PATH.img.CartGenerationDetail} alt='features-img' />
                        </div>
                        <div className='lg:w-3/5 lg:ps-7'>
                            <div className='flex flex-col gap-3'>
                                <p className='text-base'>Your Gateway to Effortless Shopping</p>
                                <h6 className='text-[55px] flex items-center'><span className='lg:pe-5 pe-2'>Cart Generation</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                                <p className='text-[22px]'>Simplify and accelerate the way you shop</p>
                                <p className='text-base'>Imagine having a knowledgeable guide at your side, helping you select products and complete your purchase seamlessly.</p>
                                <p className='mt-2 text-base'>With Cart Generation, you no longer have to navigate the intricacies of e-commerce sites alone. Our dedicated agents are here to streamline your journey, making shopping smarter, faster, and more convenient.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="lg:px-20 px-5 py-3 lg:h-[500px] relative z-20 overflow-hidden">
                <div className='absolute lg:block hidden bottom-[-20px] border-gray-200 right-[-130px] top-0 w-60 h-60 border-[1px] rounded-full'></div>
                <div className='absolute lg:block hidden bottom-t-0 border-gray-200 right-60 top-0 w-80 border-[0.5px] rounded-full'></div>
                <div className="mx-auto relative overflow-x-auto py-20">
                    <div className='flex flex-col justify-center gap-2'>
                        <h6 className='text-2xl flex justify-center items-center'><div className='w-20 border-t-[1px] border-black'></div><span className='lg:px-5 px-2 text-center'>Why We Choose AR for our product?</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                        <p className='text-base text-center'>Real-time Interaction</p>
                        <p className="text-base max-w-3xl mx-auto text-center mt-3">Enhances the overall shopping experience by giving online visitors a chance to interact with the product simply by using their cursor. All it takes is a click and a drag to view the product from every angle. This gives potential customers a sense of control. Rather than seeing when the vendor chooses, they have the power to choose for themselves, which increases overall customer satisfaction.</p>
                    </div>
                    <div className='grid md:grid-cols-4 gap-3 mt-10'>
                        <DetailExpand
                            HeadingDetail={'Real-time Interaction'}
                            DetailText={' Modern AR relies on advanced hardware, including smartphones, tablets, and specialized AR headsets and glasses.'}
                        />
                        <DetailExpand
                            HeadingDetail={'Object Recognition'}
                            DetailText={' Modern AR relies on advanced hardware, including smartphones, tablets, and specialized AR headsets and glasses.'}
                        />
                        <DetailExpand
                            HeadingDetail={'Location-based Services'}
                            DetailText={' Modern AR relies on advanced hardware, including smartphones, tablets, and specialized AR headsets and glasses.'}
                        />
                        <DetailExpand
                            HeadingDetail={'Immersive Experience'}
                            DetailText={' Modern AR relies on advanced hardware, including smartphones, tablets, and specialized AR headsets and glasses.'}
                        />
                    </div>
                </div>
            </div>
            <div className='bg-[#EFEFEF] grid lg:grid-cols-3 gap-y-9 items-start lg:px-20 px-5 py-10'>
                <Fundamentals
                    Icon={PATH.img.ArIcon1}
                    className={'text-black'}
                    HeadingText={'Real-time Interaction'}
                    ParaText={'AR provides real-time interaction with virtual elements and information'}
                />
                <Fundamentals
                    Icon={PATH.img.ArIcon2}
                    className={'text-black'}
                    HeadingText={'Immersive Experience'}
                    ParaText={'Our product aims to create a sense of immersion and realism by combining virtual objects seamlessly with the real world'}
                />
                <Fundamentals
                    Icon={PATH.img.ArIcon3}
                    className={'text-black'}
                    HeadingText={'Enhanced Visualization'}
                    ParaText={'AR can be used in various industries to visualize complex data, such as 3D models, architectural plans'}
                />
            </div>
            <div className="lg:flex items-center lg:px-20 px-5 py-10 relative overflow-hidden">
                <div className="lg:w-1/2">
                    <div className="py-10 lg:pe-10 relative">
                        <img src={PATH.img.AboutDesign} className="absolute top-0 right-36 w-44" />
                        <video loop muted playsInline autoPlay className="relative z-10 w-full lg:h-full rounded-md object-cover">
                            <source src={PATH.video.CartGenerationVideo} type="video/mp4" />
                        </video>
                    </div>
                </div>
                <div className='lg:w-1/2 flex flex-col gap-3 lg:ps-8 z-20'>
                    <div className='absolute lg:block hidden bottom-[-20px] border-gray-200 right-[-110px] top-16 w-60 h-60 z-10 border-[1px] rounded-full'></div>
                    <div className='absolute lg:block hidden bottom-t-0 border-gray-200 right-60 top-0 w-80 border-[0.5px] rounded-full rotate-90'></div>
                    <div className='absolute lg:block hidden bottom-t-0 border-gray-200 right-[27rem] bottom-0 w-80 border-[0.5px] rounded-full rotate-90'></div>
                    <p className='text-base'>The resources and capabilities that comprise the strategic advantages of a business.</p>
                    <p className='text-lg'>Get a better glimpse of AR in</p>
                    <h6 className='text-4xl flex items-center'><span className='lg:pe-5 pe-2'>our product</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                    <div className="lg:ps-16 mt-5">
                        <p className='text-base'>Our only focus is to build solution for Ecommerce, Retail, Food, Beverages, Hospitality & QSR market.</p>
                        <p className='mt-3 text-base'>5+ Product IP (Intellectual Property) owned by Computer Vision Inc having its operation in USA.</p>
                        <p className='mt-3 text-base'>We are in software development space for more than 10 years.</p>
                        <p className='mt-3 text-base'>CV Innovation Lab in Austin(USA) and Chennai(India)</p>
                    </div>
                </div>
            </div> */}
            <div className="lg:px-20 px-5 py-3 bg-[#EFEFEF] lg:h-[600px]">
                <div className="container mx-auto relative overflow-x-auto py-20">
                    <div className='flex flex-col justify-center gap-4'>
                        <h6 className='text-[32px] flex justify-center items-center'><div className='w-20 border-t-[1px] border-black'></div><span className='lg:px-5 px-2'>Core Benefits of Cart Generation</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                        <p className='text-[22px] text-center'>Redefining Shopping Efficiency. Streamlined selections, and seamless payments</p>
                        <p className="text-base max-w-3xl mx-auto text-center mt-3">Cart Generation by CVI revolutionizes the way you shop. By bringing the expertise of our live agents directly to your screen, we empower you to effortlessly pick and purchase products, ensuring a shopping experience that is not only smarter but also quicker.</p>
                        <p className="text-base max-w-3xl mx-auto text-center">Say goodbye to the complexities of online shopping and let our Cart Generation feature guide you to a future of streamlined selections, secure payments, and unparalleled convenience.</p>
                    </div>
                    <div className='grid md:grid-cols-4 gap-3 mt-10'>
                        <DetailExpand
                            HeadingDetail={'Live Agent Assistance'}
                            DetailText={'Cart Generation brings live agents directly to your shopping experience. They share their screens, guide your product selection, and offer personalized assistance, ensuring a seamless shopping journey.'}
                        />
                        <DetailExpand
                            HeadingDetail={'Simplified Checkout'}
                            DetailText={'Experience a hassle-free checkout process. Our agents provide a QR code and secure payment link via chat. Confirm your products, pay swiftly, and proceed to the final stages effortlessly.'}
                        />
                        <DetailExpand
                            HeadingDetail={'Diverse Payment Options'}
                            DetailText={'Enjoy flexibility in payment methods with Cart Generation. Choose from various secure payment gateways, including Stripe and WorldNet, tailoring your transaction to your preferences.'}
                        />
                        <DetailExpand
                            HeadingDetail={'Feedback and Improvement'}
                            DetailText={'Your opinion matters. After a successful transaction, provide feedback and ratings on your experience with our agent. Your insights help us continually refine and enhance the Cart Generation feature to serve you better.'}
                        />
                    </div>
                </div>
            </div>
            {/* <div className="lg:flex items-center lg:px-20 px-5 py-16">
                <div className="lg:w-1/2">
                    <div className="flex flex-col gap-3">
                        <p className='text-base'>A Blend Of Physical And Digital World</p>
                        <p className='text-lg'>Real Time View</p>
                        <h6 className='text-4xl flex items-center'><span className='lg:pe-5 pe-2'>Coming Soon</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                        <img src={PATH.img.ComingSoonImg} className="p-10" />
                    </div>
                </div>
                <div className='lg:w-1/2 flex flex-col ps-8'>
                    <DetailUpcoming
                        HeadingDetail={'Improved Hardware'}
                        DetailText={' Expect advancements in AR headset and glasses technology, offering better comfort, wider fields of view, and enhanced displays for a more immersive experience.'}
                    />
                    <DetailUpcoming
                        HeadingDetail={'5G Integration'}
                        DetailText={'As 5G networks become more widespread, AR applications are likely to benefit from faster and more stable data connections, reducing latency and improving real-time experiences.'}
                    />
                    <DetailUpcoming
                        HeadingDetail={'Object Recognition and Tracking'}
                        DetailText={'AR systems will become more adept at recognizing and tracking a broader range of real-world objects, enabling richer and more interactive AR experiences.'}
                    />
                    <DetailUpcoming
                        HeadingDetail={'Extended Reality (XR)'}
                        DetailText={'The blending of AR with virtual reality (VR) and mixed reality (MR) will become more common, offering a continuum of experiences in a single device.'}
                    />
                </div>
            </div> */}
            <RequestDemo
                RequesDemoHeading={'Try Cart Generation Today for a Smarter Shopping Experience'}
                RequestDemoMainHead={'Checkout Cart Generation Today'}
                RequestDemoPara={`Discover a new era of shopping efficiency with Cart Generation by CVI. It's like having a personal shopping assistant right at your fingertips.`}
                RequestDemoPara2={'Simplified checkout, diverse payment options, and your valuable feedback make Cart Generation the smart choice for hassle-free shopping. Try it today and redefine the way you shop.'}
            />
            <Testimonial />
            <Footer />
        </React.Fragment >
    );
};

export default CartGeneration;