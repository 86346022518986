import React from "react";
import { PATH } from "../../../globals/app-constants";
import DetailExpand from "../../../component/Detail_Expand";
import Fundamentals from "../../../component/FundamentalsContent";
import RequestDemo from '../../../component/RequestDemo';
import Testimonial from '../../../component/Testimonial';
import Footer from '../../../component/HOC/Footer';
import DetailUpcoming from '../../../component/Detail_Upcoming';

const AugmentedReality = () => {
    document.body.style.overflow = 'auto';
    return (
        <React.Fragment>
            <div className='lg:px-20 px-5 py-16'>
                <div className="container mx-auto">
                    <div className='flex lg:flex-row flex-col-reverse items-center mt-20'>
                        <div className='lg:w-2/5 lg:mt-0 mt-7'>
                            <img src={PATH.img.ARImg} alt='features-img' />
                        </div>
                        <div className='lg:w-3/5 lg:ps-7'>
                            <div className='flex flex-col gap-3'>
                                <p className='text-base'>Live the experience</p>
                                <h6 className='text-[55px] flex items-center'><span className='lg:pe-5 pe-2'>Augmented Reality</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                                <p className='text-[22px]'>360 Degree Product View</p>
                                <p className='text-base'>At CVI, we are excited to offer our customers a truly immersive shopping experience with augmented reality (AR). With AR, you can see our products from all angles, zoom in on specific details, and even place them in your own home to gauge the right fitment before you buy.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:px-20 px-5 py-3 lg:h-[600px] relative z-20 overflow-hidden">
                <div className='absolute lg:block hidden bottom-[-20px] border-gray-200 right-[-130px] top-0 w-60 h-60 border-[1px] rounded-full'></div>
                <div className='absolute lg:block hidden bottom-t-0 border-gray-200 right-60 top-0 w-80 border-[0.5px] rounded-full'></div>
                <div className="container mx-auto relative overflow-x-auto py-20">
                    <div className='flex flex-col justify-center gap-2'>
                        <h6 className='text-[32px] flex justify-center items-center'><div className='w-20 border-t-[1px] border-black'></div><span className='lg:px-5 px-2 text-center'>Core Benefits of AR for Shopping</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                        <p className='text-[32px] text-center'>See it like you mean it.</p>
                        <p className='text-base text-center'>Shop with AR!</p>
                        <p className="text-base max-w-3xl mx-auto text-center mt-3">Augmented reality (AR) elevates the shopping experience by providing immersive, informative, and engaging interactions. Shoppers can visualize products from all angles, examine intricate details, and even place items in their own living spaces. This leads to more informed decisions, reduced costly errors, and a heightened sense of enjoyment during the shopping process.
                        </p>
                    </div>
                    <div className='grid md:grid-cols-4 gap-3 mt-10'>
                        <DetailExpand
                            HeadingDetail={'Real-time Interaction '}
                            DetailText={'Seamlessly engage with products in real time for an immersive shopping experience.'}
                        />
                        <DetailExpand
                            HeadingDetail={'Object Recognition'}
                            DetailText={'Recognize and position products within your home, enhancing shopping accuracy.'}
                        />
                        <DetailExpand
                            HeadingDetail={'Location-Based Fitment'}
                            DetailText={`Tailor offerings to your home's location for a personalized shopping experience.`}
                        />
                        <DetailExpand
                            HeadingDetail={'Immersive Experience'}
                            DetailText={'Immerse yourself in an interactive, engaging shopping journey.'}
                        />
                    </div>
                </div>
            </div>
            <div className='bg-[#EFEFEF] px-5 lg:px-20 py-20'>
                <div className='container mx-auto lg:flex gap-y-9 items-start'>
                    <Fundamentals
                        ImgHidden={'lg:block hidden'}
                        DividerImg={PATH.img.LineBlack}
                        Icon={PATH.img.ArIcon1}
                        className={'text-black'}
                        HeadingText={'Room Scanning'}
                        ParaText={`Utilizing mobile devices' cameras and sensors, AR technology scans and recreates your physical environment in a digital context, facilitating precise placement of virtual objects and enriching home decor or room planning experiences.`}
                    />
                    <Fundamentals
                        ImgHidden={'lg:block hidden'}
                        DividerImg={PATH.img.LineBlack}
                        Icon={PATH.img.ArIcon2}
                        className={'text-black'}
                        HeadingText={'Virtual Try-Before-You-Buy'}
                        ParaText={'Shoppers can visually preview products such as clothing, furniture, or makeup in real-time using AR, ensuring they make informed purchasing decisions and minimizing returns.'}
                    />
                    <Fundamentals
                        ImgHidden={'hidden'}
                        DividerImg={PATH.img.LineBlack}
                        Icon={PATH.img.ArIcon3}
                        className={'text-black'}
                        HeadingText={'Hardware Compatibility'}
                        ParaText={'AR platforms seamlessly adapt to existing hardware graphic resources, enhancing versatility and ensuring a consistent, high-quality AR experience across a range of devices and configurations.'}
                    />
                </div>
            </div>
            <div className="lg:flex items-center lg:px-20 px-5 py-10 relative overflow-hidden">
                <div className="lg:w-1/2">
                    <div className="py-10 lg:pe-10 relative">
                        <img src={PATH.img.AboutDesign} className="absolute top-0 right-36 w-44" alt="augmented-reality" />
                        <div className="relative z-10 w-full lg:h-full rounded-md object-cover overflow-hidden">
                            <img src={PATH.img.AR_PLACEMENT} className="" alt="augmented-reality" />
                        </div>
                        {/* <video loop muted playsInline autoPlay className="relative z-10 w-full lg:h-full rounded-md object-cover">
                            <source src={PATH.video.ARvideo} type="video/mp4" />
                        </video> */}
                    </div>
                </div>
                <div className='lg:w-1/2 flex flex-col gap-3 lg:ps-8 z-20'>
                    <div className='absolute lg:block hidden bottom-[-20px] border-gray-200 right-[-110px] top-16 w-60 h-60 z-10 border-[1px] rounded-full'></div>
                    <div className='absolute lg:block hidden bottom-t-0 border-gray-200 right-60 top-0 w-80 border-[0.5px] rounded-full rotate-90'></div>
                    {/* <div className='absolute lg:block hidden bottom-t-0 border-gray-200 right-[27rem] bottom-0 w-80 border-[0.5px] rounded-full rotate-90'></div> */}
                    {/* <p className='text-base'>The resources and capabilities that comprise the strategic advantages of a business.</p> */}
                    <p className='text-[22px]'>Our Experience and</p>
                    <h6 className='text-[52px] flex items-center'><span className='lg:pe-5 pe-2'>Vision</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                    <div className="lg:ps-16 mt-5">
                        <p className='text-base'>Proglient's AR Department is dedicated to leveraging resources and capabilities for strategic advantages. They offer an immersive look into the world of Augmented Reality (AR) through their products. Their focus is on developing AR solutions tailored to the needs of industries such as Ecommerce, Retail, Food, Beverages, Hospitality, and Quick Service Restaurants (QSR).</p>
                        <p className='mt-3 text-base'>With a portfolio of over 5 proprietary Intellectual Property (IP) products, developed by Computer Vision Inc, which operates in the USA, they bring a wealth of experience to the AR space. Having been in the software development industry for more than a decade, Proglient is committed to innovation.</p>
                    </div>
                </div>
            </div>
            {/* <div className="lg:px-20 px-5 py-3 bg-[#EFEFEF] lg:h-[500px]">
                <div className="container mx-auto relative overflow-x-auto py-20">
                    <div className='flex flex-col justify-center gap-4'>
                        <h6 className='text-[32px] flex justify-center items-center'><div className='w-20 border-t-[1px] border-black'></div><span className='lg:px-5 px-2'>Benefits</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                        <p className='text-[22px] text-center'>Real-time Interaction</p>
                        <p className="text-base max-w-3xl mx-auto text-center mt-3"> AR can provide remote support by overlaying digital information on a live video feed. This is particularly valuable in fields like field service, where experts can guide echnicians through complex tasks..</p>
                    </div>
                    <div className='grid md:grid-cols-4 gap-3 mt-10'>
                        <DetailExpand
                            HeadingDetail={'Enhanced User Experience'}
                            DetailText={'AR adds digital elements to the real world, creating immersive and interactive experiences.'}
                        />
                        <DetailExpand
                            HeadingDetail={'Enhanced Visualization'}
                            DetailText={'In fields like architecture, design, and engineering, AR enables professionals to visualize and manipulate 3D models in the real world.'}
                        />
                        <DetailExpand
                            HeadingDetail={'Increased Efficiency'}
                            DetailText={'AR can improve efficiency in various industries by providing real-time information, guidance, and data overlays.'}
                        />
                        <DetailExpand
                            HeadingDetail={'Remote Assistance'}
                            DetailText={'AR can provide remote support by overlaying digital information on a live video feed.'}
                        />
                    </div>
                </div>
            </div> */}
            <div className="lg:flex items-center lg:px-20 px-5 py-16">
                <div className="lg:w-1/2">
                    <div className="flex flex-col gap-3">
                        <p className='text-base'>Experience the Perfect Fusion of Real and Virtual Worlds</p>
                        <h6 className='text-4xl flex items-center'><span className='lg:pe-5 pe-2'>Stay Tuned for Exciting Developments</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                        <img src={PATH.img.AR_MORE} className="p-10" alt="ComingSoon" />
                    </div>
                </div>
                <div className='lg:w-1/2 flex flex-col ps-8'>
                    <DetailUpcoming
                        HeadingDetail={'Elevated Hardware'}
                        DetailText={'Anticipate cutting-edge AR headsets and glasses, promising superior comfort, expansive field of view, and enhanced displays for the ultimate immersive adventure.'}
                    />
                    <DetailUpcoming
                        HeadingDetail={'5G Integration'}
                        DetailText={'As 5G networks expand, brace for lightning-fast AR experiences with minimal latency, ensuring seamless real-time interactions.'}
                    />
                    <DetailUpcoming
                        HeadingDetail={'Smart Object Recognition'}
                        DetailText={'Prepare for AR systems that effortlessly identify and track an extensive array of real-world objects, unlocking a realm of immersive and interactive experiences.'}
                    />
                    <DetailUpcoming
                        HeadingDetail={'Embrace Extended Reality (XR)'}
                        DetailText={'The future blends AR with virtual reality (VR) and mixed reality (MR), offering an all-encompassing journey within a single device. Stay connected for a revolution in augmented experiences.'}
                    />
                </div>
            </div>
            <RequestDemo
                RequesDemoHeading={'Explore AR Shopping Now'}
                RequestDemoMainHead={'Live Product Preview'}
                RequestDemoPara={`Are you ready to unlock the full potential of our cutting-edge AR shopping solutions? Dive into a firsthand AR shopping experience by scheduling a personalized demo. Our experts are here to guide you through the incredible features and benefits of our AR product.`}
                RequestDemoPara2={'Request a demo today and take the first step towards a brighter, more efficient AR shopping future.'} />
            <Testimonial />
            <Footer />
        </React.Fragment >
    );
};

export default AugmentedReality;