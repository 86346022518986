import React from 'react';
import TableHead from '../component/Table_Head';

const Fundamentals = ({ Icon, HeadingText, ParaText }) => {
    return (
        <div className="relative py-16">
            <div className='lg:px-20 px-5'>
                <div className='container mx-auto flex flex-col gap-2 '>
                    <p className='text-base'>Pricing</p>
                    <h6 className='text-[52px] flex items-center'><span className='lg:pe-5 pe-2'>Ready to get started?</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                    <p className='text-2xl'>Choose a plan tailored to your needs</p>
                </div>
            </div>
            <div className='overflow-x-auto lg:px-20 ps-5'>
                <div className='container mx-auto'>
                    <table data-aos="zoom-in" className="w-full text-left bg-[#FAFAFA] mt-14">
                        <thead className="">
                            <tr>
                                <th scope="col" className="w-[32%] px-6 pt-7 pb-3 border-[1px] border-l-0 border-t-0 border-[#D9D9D9] text-[#CDCCCC] text-xl font-normal">
                                    Displaying a price list on a
                                    per-month basis .
                                </th>
                                <th scope="col" className="w-[22.6%] px-6 pt-7 pb-3 border-[1px] border-t-0 border-[#D9D9D9] text-base font-normal">
                                    <TableHead
                                        TableHeadText={'Starters'}
                                        Price={'199'}
                                    />
                                </th>
                                <th scope="col" className="w-[22.6%] px-6 pt-7 pb-3 border-[1px] border-t-0 border-[#D9D9D9] text-base font-normal">
                                    <TableHead
                                        TableHeadText={'Professionals'}
                                        Price={'499'}
                                    />
                                </th>
                                <th scope="col" className="w-[22.6%] px-6 pt-7 pb-3 border-[1px] border-r-0 border-t-0 border-[#D9D9D9] text-base font-normal">
                                    <TableHead
                                        TableHeadText={'Enterprises'}
                                        Price={'799'}
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="">
                                <td className="px-8 py-4 text-base border-[1px] border-l-0 border-t-0 border-[#D9D9D9]">
                                    Account Managing Portal
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-t-0 border-[#D9D9D9]">
                                    2
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-t-0 border-[#D9D9D9]">
                                    15
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-r-0 border-t-0 border-[#D9D9D9]">
                                    50
                                </td>
                            </tr>
                            <tr className="">
                                <td className="px-8 py-4 text-base border-[1px] border-l-0 border-t-0 border-[#D9D9D9]">
                                    Live Streaming (Additional Charges $ 0.004/min)
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-t-0 border-[#D9D9D9]">
                                    2000mins
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-t-0 border-[#D9D9D9]">
                                    5000mins
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-r-0 border-t-0 border-[#D9D9D9]">
                                    7500mins
                                </td>
                            </tr>
                            <tr className="">
                                <td className="px-8 py-4 text-base border-[1px] border-l-0 border-t-0 border-[#D9D9D9]">
                                    Live chat
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-t-0 border-[#D9D9D9]">
                                    <div className='flex justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                    </div>
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-t-0 border-[#D9D9D9]">
                                    <div className='flex justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                    </div>
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-r-0 border-t-0 border-[#D9D9D9]">
                                    <div className='flex justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                            <tr className="">
                                <td className="px-8 py-4 text-base border-[1px] border-l-0 border-t-0 border-[#D9D9D9]">
                                    Payment Integration
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-t-0 border-[#D9D9D9]">
                                    <div className='flex justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                    </div>
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-t-0 border-[#D9D9D9]">
                                    <div className='flex justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                    </div>
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-r-0 border-t-0 border-[#D9D9D9]">
                                    <div className='flex justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                            <tr className="">
                                <td className="px-8 py-4 text-base border-[1px] border-l-0 border-t-0 border-[#D9D9D9]">
                                    99.99% Availability
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-t-0 border-[#D9D9D9]">
                                    <div className='flex justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                    </div>
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-t-0 border-[#D9D9D9]">
                                    <div className='flex justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                    </div>
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-r-0 border-t-0 border-[#D9D9D9]">
                                    <div className='flex justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                            <tr className="">
                                <td className="px-8 py-4 text-base border-[1px] border-l-0 border-t-0 border-[#D9D9D9]">
                                    Usage Reporting
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-t-0 border-[#D9D9D9]">
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-t-0 border-[#D9D9D9]">
                                    <div className='flex justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                    </div>
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-r-0 border-t-0 border-[#D9D9D9]">
                                    <div className='flex justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                            <tr className="">
                                <td className="px-8 py-4 text-base border-[1px] border-l-0 border-t-0 border-[#D9D9D9]">
                                    Screen Sharing
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-t-0 border-[#D9D9D9]">
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-t-0 border-[#D9D9D9]">
                                    <div className='flex justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                    </div>
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-r-0 border-t-0 border-[#D9D9D9]">
                                    <div className='flex justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                            <tr className="">
                                <td className="px-8 py-4 text-base border-[1px] border-l-0 border-t-0 border-[#D9D9D9]">
                                    Fully Custom Branding
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-t-0 border-[#D9D9D9]">
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-t-0 border-[#D9D9D9]">
                                    <div className='flex justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                    </div>
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-r-0 border-t-0 border-[#D9D9D9]">
                                    <div className='flex justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                            <tr className="">
                                <td className="px-8 py-4 text-base border-[1px] border-l-0 border-t-0 border-[#D9D9D9]">
                                    Customer Feedback
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-t-0 border-[#D9D9D9]">
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-t-0 border-[#D9D9D9]">
                                    <div className='flex justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                    </div>
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-r-0 border-t-0 border-[#D9D9D9]">
                                    <div className='flex justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                            <tr className="">
                                <td className="px-8 py-4 text-base border-[1px] border-l-0 border-t-0 border-[#D9D9D9]">
                                    Cart Generator
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-t-0 border-[#D9D9D9]">
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-t-0 border-[#D9D9D9]">
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-r-0 border-t-0 border-[#D9D9D9]">
                                    <div className='flex justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                            <tr className="">
                                <td className="px-8 py-4 text-base border-[1px] border-l-0 border-b-0 border-t-0 border-[#D9D9D9]">
                                    Augmented reality
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-b-0 border-t-0 border-[#D9D9D9]">
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-b-0 border-t-0 border-[#D9D9D9]">
                                </td>
                                <td className="px-8 py-4 text-center text-base border-[1px] border-b-0 border-r-0 border-t-0 border-[#D9D9D9]">
                                    <div className='flex justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Fundamentals;