import React, { useState } from "react";
import { PATH } from "../globals/app-constants";
import SideBarIcons from "./SidebarIcons";
import { useNavigate } from "react-router-dom";

const SideMenu = ({ showMenu, setShowMenu }) => {
    const [showSubMenu, setshowSubMenu] = useState(false);
    const navigate = useNavigate();
    const handleClose = () => {
        setShowMenu(!showMenu);
    };
    const handleNavigate = (naviagteTo) => {
        navigate(naviagteTo);
        setShowMenu(false);
    };
    const handleFeature = () => {
        setshowSubMenu(!showSubMenu);
    };
    return (
        <div className='fixed top-0 left-0 z-50 bg-black text-white p-8 w-full h-full flex flex-col gap-2'>
            <div className="flex justify-between items-center">
                <img src={PATH.img.FooterLogo} alt='logo' className='w-10' />
                <button className='w-8 h-8 bg-slate-600 flex items-center justify-center rounded-full' onClick={handleClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <div className="grid lg:grid-cols-5 flex-1">
                <div className="col-span-1 hidden lg:flex flex-col justify-end">
                    <div className='hidden lg:flex items-end'>
                        <div className='flex flex-col justify-center gap-4'>
                            <SideBarIcons
                                IconPath={PATH.img.Facebook}
                                MediaName={'Facebook'}
                            />
                            <SideBarIcons
                                IconPath={PATH.img.Twitter}
                                MediaName={'Twitter'}
                            />
                            <SideBarIcons
                                IconPath={PATH.img.Instagram}
                                MediaName={'Instagram'}
                            />
                            <SideBarIcons
                                IconPath={PATH.img.Linkedin}
                                MediaName={'LinkedIn'}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-span-2 mt-12 lg:mt-0 flex flex-col justify-between">
                    <ul className='list-none grid gap-3'>
                        <li><button ><span className='text-3xl font-medium text-start uppercase tracking-wider flex items-center group' onClick={() => handleNavigate('/')}>Home
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="ps-3 w-0 h-12 group-hover:w-12 transition-all ease-in duration-300">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                            </svg>
                        </span></button></li>
                        <li><button><span className='text-3xl font-medium text-start uppercase tracking-wider flex items-center group' onClick={() => handleNavigate('/aboutus')}>About us
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="ps-3 w-0 h-12 group-hover:w-12 transition-all ease-in duration-300">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                            </svg></span></button></li>
                        <li><button><span className='text-3xl font-medium text-start uppercase tracking-wider flex items-center group' onClick={() => handleFeature('/augmented-reality')}>Features
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="ps-3 w-0 h-12 group-hover:w-12 transition-all ease-in duration-300">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                            </svg></span></button></li>
                        {
                            showSubMenu &&
                            <div className="flex flex-col gap-2 py-2">
                                <p className="text-lg cursor-pointer inline-block" onClick={() => handleNavigate('/live-agent')}>- LIVE AGENT</p>
                                <p className="text-lg cursor-pointer inline-block" onClick={() => handleNavigate('/augmented-reality')}>- AUGMENTED REALITY</p>
                                <p className="text-lg cursor-pointer inline-block" onClick={() => handleNavigate('/3d-view')}>- 3D VIEW</p>
                                <p className="text-lg cursor-pointer inline-block" onClick={() => handleNavigate('/cart-generation')}>- CART GENERATION</p>
                                <p className="text-lg cursor-pointer inline-block" onClick={() => handleNavigate('/small-language')}>- SMALL LANGUAGE MODEL</p>
                            </div>
                        }

                        <li><button><span className='text-3xl font-medium text-start uppercase tracking-wider flex items-center group' onClick={() => handleNavigate('/pricing')}>Pricing
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="ps-3 w-0 h-12 group-hover:w-12 transition-all ease-in duration-300">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                            </svg></span></button></li>
                        <li><button><span className='text-3xl font-medium text-start uppercase tracking-wider flex items-center group' onClick={() => handleNavigate('/contactus')}>Contact us
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="ps-3 w-0 h-12 group-hover:w-12 transition-all ease-in duration-300">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                            </svg></span></button></li>
                    </ul>
                    <ul className='list-none hidden lg:grid gap-3'>
                        <li><button onClick={() => handleNavigate('/privacy-policy')}><span className='hover:underline text-sm'>Privacy Policy</span></button></li>
                        {/* <li><button><span className='hover:underline text-sm'>Terms and Conditions</span></button></li> */}
                        {/* <li><button><span className='hover:underline text-sm'>Site Map</span></button></li> */}
                    </ul>
                </div>
                <div className="col-span-2">
                    {/* <div class="bg-black text-white border-[1px] border-[#30302F] p-8 rounded-lg max-w-md mx-auto h-full w-[28rem] flex flex-col justify-between">
                        <p className="text-base">Schedule a Demo</p>
                        <div className="">
                            <div className="flex flex-col gap-2">
                                <img src={PATH.img.CallPerson} className="mx-auto" width={56} alt="chat-person" />
                                <p className="text-[#424242] t
                            ext-xs text-center">Our engineer waiting for you to show the Demo</p>
                            </div>
                            <div className="flex flex-col gap-y-6">
                                <div class="flex justify-start">
                                    <div class="w-8 h-8 bg-[#808080] rounded-full flex-shrink-0 flex items-center justify-center">
                                        <img src={PATH.img.ChatUser} width={15} alt="User Avatar" class="rounded-full" />
                                    </div>
                                    <div class="ms-2.5">
                                        <div class="bg-[#808080] text-white px-[22px] py-1.5 text-base rounded max-w-[246px]">
                                            This is a message from the user. Lorem ipsum dolor sit amet.
                                        </div>
                                    </div>
                                </div>
                                <div class="flex justify-end w-full">
                                    <div class="bg-white text-black px-[22px] py-1.5 text-base rounded max-w-[246px]">
                                        This is a message from the user. Lorem ipsum dolor sit amet.
                                    </div>
                                </div>
                                <div class="flex justify-start">
                                    <div class="w-8 h-8 bg-[#808080] rounded-full flex-shrink-0 flex items-center justify-center">
                                        <img src={PATH.img.ChatUser} width={15} alt="User Avatar" class="rounded-full" />
                                    </div>
                                    <div class="ms-2.5">
                                        <div class="bg-[#808080] text-white px-[22px] py-1.5 text-base rounded max-w-[246px]">
                                            This is a message from the user. Lorem ipsum dolor sit amet.
                                        </div>
                                    </div>
                                </div>
                                <div class="flex justify-end w-full">
                                    <div class="bg-white text-black px-[22px] py-1.5 text-base rounded max-w-[246px]">
                                        This is a message from the user. Lorem ipsum dolor sit amet.
                                    </div>
                                </div>
                                <div class="flex justify-start">
                                    <div class="w-8 h-8 bg-[#808080] rounded-full flex-shrink-0 flex items-center justify-center">
                                        <img src={PATH.img.ChatUser} width={15} alt="User Avatar" class="rounded-full" />
                                    </div>
                                    <div class="ms-2.5">
                                        <div class="bg-[#808080] text-white px-[22px] py-1.5 text-base rounded max-w-[246px]">
                                            This is a message from the user. Lorem ipsum dolor sit amet.
                                        </div>
                                    </div>
                                </div>
                                <div class="flex justify-end w-full">
                                    <div class="bg-white text-black px-[22px] py-1.5 text-base rounded max-w-[246px]">
                                        This is a message from the user. Lorem ipsum dolor sit amet.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div class="border-white border-[1px] rounded-full">
                                <form class="flex items-center border-0 px-4 py-2">
                                    <input
                                        type="text"
                                        class="w-full pe-5 placeholder:text-ellipsis placeholder:overflow-hidden bg-transparent rounded-full focus:outline-none placeholder:text-base placeholder:text-white"
                                        placeholder="Hi There, I want to see your demo."
                                    />
                                    <button
                                        type="submit"
                                        class="text-white rounded-full transition duration-300"
                                    >
                                        <img src={PATH.img.SendIcon} alt="send-icon" />
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default SideMenu;