import React from "react";
import { PATH } from "../../../globals/app-constants";
import DetailExpand from "../../../component/Detail_Expand";
import Fundamentals from "../../../component/FundamentalsContent";
import RequestDemo from '../../../component/RequestDemo';
import Testimonial from '../../../component/Testimonial';
import Footer from '../../../component/HOC/Footer';
import DetailUpcoming from '../../../component/Detail_Upcoming';

const LiveAgent = () => {
    document.body.style.overflow = 'auto';
    return (
        <React.Fragment>
            <div className='lg:px-20 px-5 py-16'>
                <div className="container mx-auto">
                    <div className='flex lg:flex-row flex-col-reverse items-center mt-20'>
                        <div className='lg:w-2/5 lg:mt-0 mt-7'>
                            <img src={PATH.img.LiveAgentDetail} alt='features-img' />
                        </div>
                        <div className='lg:w-3/5 lg:ps-7'>
                            <div className='flex flex-col gap-3'>
                                <p className='text-base'>Right Support Made Easy</p>
                                <h6 className='text-[55px] flex items-center'><span className='lg:pe-5 pe-2'>Live Agent</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                                <p className='text-[22px]'>Where real-time support meets customer delight</p>
                                <p className='mt-2 text-base'>Discover Seamless Support with Live Agent! Our innovative platform brings real-time engagement to your fingertips, connecting customers instantly with knowledgeable agents via chat, email, and video calls. Experience personalized assistance, swift issue resolution, and 24/7 support, enhancing your customer journey.</p>
                                <p className='mt-2 text-base'>Get the most out of your online business with Live Agent – Your Pathway to Exceptional Customer Experiences!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:px-20 px-5 py-3 lg:h-[600px] overflow-hidden relative">
                <div className='absolute lg:block hidden bottom-[-20px] border-gray-200 right-[-130px] top-0 w-60 h-60 border-[1px] rounded-full'></div>
                <div className='absolute lg:block hidden bottom-t-0 border-gray-200 right-60 top-0 w-80 border-[0.5px] rounded-full'></div>
                <div className="container mx-auto relative py-5 lg:py-20">
                    <div className='flex flex-col justify-center gap-4'>
                        <h6 className='text-[32px] flex justify-center items-center text-center'><div className='w-20 border-t-[1px] border-black'></div><span className='lg:px-5 px-2'>Indeed, Live Agent for You!</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                        <p className='text-[22px] text-center'>Instant-Personalized-Support</p>
                        <p className="text-base max-w-3xl mx-auto text-center mt-3">Opt for Live Agent to experience instant solutions to your inquiries, ensuring a swift and satisfying customer experience.
                            Enjoy personalized support tailored to your needs, making your interactions with us more engaging and valuable. With Live Agent, you'll have access to real-time assistance 24/7, ensuring your concerns are addressed promptly, day or night.</p>
                    </div>
                    <div className='grid md:grid-cols-4 gap-3 mt-10'>
                        <DetailExpand
                            hoverHeight={'h-40'}
                            HeadingDetail={'Real-Time Interaction'}
                            DetailText={'Live Agent provides immediate, real-time communication channels, including chat and video calls, connecting customers with knowledgeable support agents instantly. This ensures that customers receive timely assistance and can resolve issues promptly, enhancing their experience.'}
                        />
                        <DetailExpand
                            hoverHeight={'h-40'}
                            HeadingDetail={'Personalization'}
                            DetailText={'The platform offers a personalized approach to customer support, enabling agents to tailor their responses and solutions to individual customer needs and preferences. This personal touch creates a more engaging and customer-centric experience, increasing satisfaction and loyalty.'}
                        />
                        <DetailExpand
                            hoverHeight={'h-40'}
                            HeadingDetail={'Multi-Channel Support'}
                            DetailText={'Live Agent supports a variety of communication channels, including live video calling, messaging, and email. This multi-channel approach allows customers to engage with support in their preferred manner, providing flexibility and convenience for effective issue resolution.'}
                        />
                        <DetailExpand
                            hoverHeight={'h-40'}
                            HeadingDetail={'24/7 Availability'}
                            DetailText={'Live Agent ensures round-the-clock customer support, allowing customers to seek assistance anytime. This continuous availability is precious for addressing inquiries and problems outside of regular business hours, providing customers with the support they need when they need it, and ultimately improving customer satisfaction.'}
                        />
                    </div>
                </div>
            </div>
            <div className='bg-[#EFEFEF] px-5 lg:px-20 py-16'>
                <div className='container mx-auto lg:flex gap-y-9 items-start'>
                    <Fundamentals
                        ImgHidden={'lg:block hidden'}
                        DividerImg={PATH.img.LineBlack}
                        Icon={PATH.img.ArIcon1}
                        className={'text-black'}
                        HeadingText={'Age Verification'}
                        ParaText={'Agents can verify the age of customers in real time, particularly for age-restricted products.'}
                    />
                    <Fundamentals
                        ImgHidden={'lg:block hidden'}
                        DividerImg={PATH.img.LineBlack}
                        Icon={PATH.img.ArIcon2}
                        className={'text-black'}
                        HeadingText={'Problem-Solving'}
                        ParaText={'Live agents are available to assist with inquiries, issues, and requests, which can lead to faster issue resolution and improved customer satisfaction.'}
                    />
                    <Fundamentals
                        ImgHidden={'hidden'}
                        DividerImg={PATH.img.LineBlack}
                        Icon={PATH.img.ArIcon3}
                        className={'text-black'}
                        HeadingText={'Payment Integration'}
                        ParaText={'The system is integrated with payment gateways like Stripe, Adyen, WorldNet, authorize.net, and Razorpay to facilitate the payment process.'}
                    />
                </div>
            </div>
            <div className="lg:px-20 px-5 relative overflow-hidden">
                <div className="container mx-auto py-20 lg:flex items-center ">
                    <div className="lg:w-1/2">
                        <div className="py-10 lg:pe-10 relative">
                            <img src={PATH.img.AboutDesign} className="absolute top-0 right-36 w-44" alt="live-agent" />
                            <video loop muted playsInline autoPlay className="relative z-10 w-full lg:h-full rounded-md object-cover">
                                <source src={PATH.video.LiveAgentVideo} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                    <div className='lg:w-1/2 flex flex-col gap-3 lg:ps-8 z-20'>
                        <div className='absolute lg:block hidden bottom-[-20px] border-gray-200 right-[-110px] top-16 w-60 h-60 z-10 border-[1px] rounded-full'></div>
                        <div className='absolute lg:block hidden bottom-t-0 border-gray-200 right-60 top-0 w-80 border-[0.5px] rounded-full rotate-90'></div>
                        <div className='absolute lg:block hidden bottom-t-0 border-gray-200 right-[27rem] bottom-0 w-80 border-[0.5px] rounded-full rotate-90'></div>
                        <p className='text-base'>Implement Live Agent for a Substantial Positive Impact Today!</p>
                        <p className='text-[22px]'>Catch a glimpse on Live Agent</p>
                        <h6 className='text-[52px] flex items-center'><span className='lg:pe-5 pe-2'>Additional features</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                        <div className="lg:ps-16 mt-5 grid gap-2">
                            <p className='text-base flex gap-4'><span>-</span> <span>Immersive screen sharing for dynamic presentations.Seamless integration with leading payment gateways.Streamlined vendor and agent onboarding processes.</span></p>
                            <p className='text-base flex gap-4'><span>-</span> <span>Real-time age verification for age-restricted products.</span></p>
                            <p className="text-base flex gap-4"><span>-</span> <span>Enhanced customer engagement through AR rendering.</span></p>
                            <p className="text-base flex gap-4"><span>-</span> <span>Custom virtual backgrounds to match the retail environment.</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:px-20 px-5 py-3 lg:h-[550px] bg-[#EFEFEF]">
                {/* <div className='absolute lg:block hidden bottom-[-20px] border-gray-200 right-[-130px] top-0 w-60 h-60 border-[1px] rounded-full'></div> */}
                {/* <div className='absolute lg:block hidden bottom-t-0 border-gray-200 right-60 top-0 w-80 border-[0.5px] rounded-full'></div> */}
                <div className="container mx-auto relative py-20">
                    <div className='flex flex-col justify-center gap-4'>
                        <h6 className='text-[32px] flex justify-center items-center'><div className='w-20 border-t-[1px] border-black'></div><span className='lg:px-5 px-2'>Core Benefits</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                        <p className='text-[22px] text-center'>Live Agent, your ultimate support solution</p>
                        <p className="text-base max-w-3xl mx-auto text-center mt-3">Guarantees easy access to product information, real-time engagement through chat, email, and video calls, personalized support, expert issue resolution, and 24/7 availability, ensuring a seamless and satisfying customer experience.</p>
                    </div>
                    <div className='grid md:grid-cols-4 gap-3 mt-10'>
                        <DetailExpand
                            mobHeight={'h-14'}
                            hoverHeight={'h-16'}
                            HeadingDetail={'Product Information Accessibility'}
                            DetailText={'Customers easily access product details for informed purchases.'}
                        />
                        <DetailExpand
                            mobHeight={'h-14'}
                            hoverHeight={'h-16'}
                            HeadingDetail={'Real-Time Engagement'}
                            DetailText={'Live agents engage customers instantly via chat, email, or video calls.'}
                        />
                        <DetailExpand
                            mobHeight={'h-14'}
                            hoverHeight={'h-16'}
                            HeadingDetail={'Personalized Support '}
                            DetailText={'Agents tailor responses to meet individual customer needs and preferences.'}
                        />
                        <DetailExpand
                            mobHeight={'h-14'}
                            hoverHeight={'h-16'}
                            HeadingDetail={'Issue Resolution'}
                            DetailText={'Agents are trained to troubleshoot, answer questions, and resolve problems effectively.'}
                        />
                    </div>
                </div>
            </div>
            <div className="">
                <div className="container mx-auto lg:px-20 px-5 py-20 lg:flex items-start">
                    <div className="lg:w-1/2">
                        <div className="flex flex-col gap-3">
                            <p className='text-base'>A Digital Human Customer Service Representative</p>
                            <p className='text-lg'>One-Stop Solution </p>
                            <h6 className='text-[52px] flex items-center'><span className='lg:pe-5 pe-2'>The Process</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                            <img src={PATH.img.ComingSoonImg} className="p-10" alt="ComingSoon" />
                        </div>
                    </div>
                    <div className='lg:w-1/2 flex flex-col ps-8'>
                        <DetailUpcoming
                            HeadingDetail={'Communication and Presentation Enhancement'}
                            DetailText={'Implement a cloud phone system for video calls on various devices. Enable immersive screen sharing for virtual presentations as effective as in-person meetings.'}
                        />
                        <DetailUpcoming
                            HeadingDetail={' Payment Integration and Vendor Onboarding'}
                            DetailText={'Integrate payment gateways with E-commerce platforms for a streamlined payment process. Establish a vendor onboarding process using Live Agent as middleware for retail engagement.'}
                        />
                        <DetailUpcoming
                            HeadingDetail={'Agent Management and Customer Support'}
                            DetailText={'Streamline agent onboarding and management through the Vendor Application. Offer real-time age verification by agents for age-restricted product purchases. Enable customers to schedule support calls for round-the-clock assistance.'}
                        />
                        <DetailUpcoming
                            HeadingDetail={'Augmented Reality (AR) Integration'}
                            DetailText={'Provide customers with an AR feature to visualize products in their environment. Enhance the customer experience with AR-enabled product views.'}
                        />
                        <DetailUpcoming
                            HeadingDetail={'Customization and Visual Experience'}
                            DetailText={`Implement a Virtual Background feature for agents to personalize their surroundings based on the customer's retail environment, enhancing the visual experience.`} />
                    </div>
                </div>
            </div>
            <div className='bg-[#EFEFEF] px-5 lg:px-20 py-20'>
                <div className='container mx-auto lg:flex gap-y-9 items-start'>
                    <Fundamentals
                        ImgHidden={'lg:block hidden'}
                        DividerImg={PATH.img.LineBlack}
                        Icon={PATH.img.ArIcon1}
                        className={'text-black'}
                        HeadingText={'Call Scheduling'}
                        ParaText={'Call Scheduling empowers customers to set appointments with agents for personalized assistance, ensuring that support is available at their convenience.'}
                    />
                    <Fundamentals
                        ImgHidden={'lg:block hidden'}
                        DividerImg={PATH.img.LineBlack}
                        Icon={PATH.img.ArIcon2}
                        className={'text-black'}
                        HeadingText={'Customer Age Verification'}
                        ParaText={`Customer Age Verification streamlines the process of confirming a customer's age for purchasing restricted products, maintaining compliance with regulations, and ensuring a baseooth shopping experience.`} />
                    <Fundamentals
                        ImgHidden={'hidden'}
                        DividerImg={PATH.img.LineBlack}
                        Icon={PATH.img.ArIcon3}
                        className={'text-black'}
                        HeadingText={'Payment Integration'}
                        ParaText={'The system is integrated with payment gateways like Stripe, Adyen, WorldNet, authorize.net, and Razorpay to facilitate the payment process.'}
                    />
                </div>
            </div>
            <RequestDemo
                RequesDemoHeading={'Ready to experience the power of Live Agent for yourself?'}
                RequestDemoMainHead={'Get LIVE Demo'}
                RequestDemoPara={`See firsthand how Live Agent enhances real-time interactions, personalizes support, and offers a range of valuable features like cart generation and age verification. Take the next step in elevating your customer experience. Click 'Book a Demo Now' and embark on a journey to superior support and increased customer satisfaction.`} />
            <Testimonial />
            <Footer />
        </React.Fragment >
    );
};

export default LiveAgent;