import React from "react";

const MediaIcons = ({ IconPath, ImgWidth }) => {
    return (
        <div className='w-12 h-12 bg-[#323131] rounded-full flex items-center justify-center'>
            <img src={IconPath} width={ImgWidth} alt='facebook' />
        </div>
    )
}

export default MediaIcons
