import React from "react";
import { PATH } from "../../globals/app-constants";
import RequestDemo from '../../component/RequestDemo';
import Testimonial from '../../component/Testimonial';
import Footer from '../../component/HOC/Footer';

const ContactUs = () => {
    document.body.style.overflow = 'auto';
    return (
        <React.Fragment>
            <div className='lg:px-20 px-5 lg:py-20 py-8'>
                <div className='container mx-auto flex lg:flex-row flex-col-reverse items-center mt-5'>
                    <div className='lg:w-[28%] lg:mt-0 mt-7 relative h-full'>
                        <p className="text-[22px]">Behind the Brand: </p>
                        <p className="text-[22px]">A Tale of Excellence.</p>
                        <img src={PATH.img.AboutDesign} className="w-52 absolute right-16 bottom-[-70px]" alt='features-img' />
                    </div>
                    <div className='lg:w-[72%] lg:ps-7 lg:mt-0 mt-9'>
                        <div className='flex flex-col gap-3'>
                            <p className='text-base'>Get in Touch, Let's Talk!.</p>
                            <h6 className='text-[55px] flex items-center lg:mt-6 mt-2'><span className='lg:pe-5 pe-2'>Contact Us</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                            <p className='text-base'>Let’s Connect & Change Retail Shopping
                                Perspective Together</p>
                            <p className='mt-2 text-base'>
                                Have questions, ideas, or feedback? We're here to listen and assist. Contact our team, and let's shape the future together. Get in touch, and we'll respond promptly to your inquiries, offering solutions tailored to your needs.</p>
                            {/* <p className='mt-2 text-base'>Business Hours: 10:00AM - 7:00PM.</p> */}
                        </div>
                    </div>
                </div>
            </div>
            <RequestDemo
                RequesDemoHeading={'Ready to experience the power of Live Agent for yourself?'}
                RequestDemoMainHead={'Get LIVE Demo'}
                RequestDemoPara={`See firsthand how Live Agent enhances real-time interactions, personalizes support, and offers a range of valuable features like cart generation and age verification. Take the next step in elevating your customer experience. Click 'Book a Demo Now' and embark on a journey to superior support and increased customer satisfaction.`} />
            <div className='relative z-10'>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6387.114257998104!2d-80.19305219556912!3d25.772577288939463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b69c4a1692ab%3A0x42cf866197db33f8!2s150%20SE%202nd%20Ave%203rd%20floor.Suite%20300%2C%20Miami%2C%20FL%2033131%2C%20USA!5e0!3m2!1sen!2sin!4v1703599372689!5m2!1sen!2sin"
                    title="Address"
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    loading="lazy"></iframe>
            </div>
            <Testimonial />
            <Footer />
        </React.Fragment >
    );
};

export default ContactUs;