import React from 'react';

const BannerContent = ({ ImgPosition, CompetencyName, className, IconSize, onMouseOver }) => {
    return (
        <div onMouseOver={onMouseOver} className={`group border-b-[0.5px] lg:border-[0.5px] border-gray-400 ${className} flex flex-col gap-4 items-center h-full justify-center`}>
            <div className={`competency_section_height flex flex-col gap-4 items-center justify-center`}>
                <img src={ImgPosition} className='competency_img' alt='competency-img' />
                {/* <button className={`h-0 p-0 border-0 group-hover:h-7 group-hover:items-center group-hover:border-[1px] group-hover:border-white transition-all duration-300 ease-in flex gap-1 overflow-hidden rounded-md group-hover:px-2 group-hover:py-4 opacity-0 group-hover:opacity-100`}>
                    <img className={`h-0 overflow-hidden group-hover:h-6`} src={PATH.img.WatchVideo} width={13} alt='watch-video' />
                    <span className={`text-white watch_video`}>Watch Video</span>
                </button> */}
                {/* <WatchVideo
                    className={'h-0 p-0 border-0 group-hover:h-7 group-hover:items-center group-hover:border-[1px] group-hover:border-white transition-all duration-300 ease-in'}
                    TextSize={'h-0 overflow-hidden group-hover:h-6'} 
                    IconSize={'h-0 overflow-hidden group-hover:h-6'}
                /> */}
                <p className='compentency_name'>{CompetencyName}</p>
            </div>
        </div>
    );
};

export default BannerContent;