import React from "react";
import { PATH } from "../../globals/app-constants";
import RequestDemo from '../../component/RequestDemo';
import Testimonial from '../../component/Testimonial';
import Footer from '../../component/HOC/Footer';

const AboutUs = () => {
    document.body.style.overflow = 'auto';
    return (
        <React.Fragment>
            <div className='lg:px-20 px-5 lg:py-20 py-8'>
                <div className='container mx-auto flex lg:flex-row flex-col-reverse items-center mt-5'>
                    <div className='lg:w-[28%] lg:mt-0 mt-7 relative h-full'>
                        <p className="text-[22px]">Behind the Brand: </p>
                        <p className="text-[22px]">A Tale of Excellence.</p>
                        <img src={PATH.img.AboutDesign} className="w-52 absolute right-16 bottom-[-70px]" alt='features-img' />
                    </div>
                    <div className='lg:w-[72%] lg:ps-7 lg:mt-0 mt-9'>
                        <div className='flex flex-col gap-3'>
                            <p className='text-base'>Empowering Connections - Solving Issues - Ensuring Satisfaction.</p>
                            <h6 className='text-[55px] flex items-center'><span className='lg:pe-5 pe-2'>Who We Are</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                            <p className='text-base'>At Live Agent, we embody the ethos of seamless connections and unparalleled support, leveraging cutting-edge computer vision technology.</p>
                            <p className='mt-2 text-base'>Pioneers in Live Agent solutions, we redefine customer experiences by integrating advanced computer vision tools. Our commitment extends to real-time assistance, leveraging this technology for personalized interactions and innovative problem-solving. By harnessing the power of computer vision, we empower businesses to transcend traditional support methods, ensuring swift query resolution and heightened customer satisfaction.</p>

                            <p className='mt-2 text-base'>Driven by a passion for innovation, we stand as a beacon of reliability, reshaping customer service with leading-edge tools for a more connected and gratifying future.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-10 bg-[#FAFAFA]">
                <div className='lg:px-20 px-5 py-24'>
                    <div className="container mx-auto lg:flex lg:flex-row items-center gap-8">
                        <div className='lg:w-[60%]'>
                            <div className='flex flex-col gap-3'>
                                <p className='text-base'>Unified expertise: Driving Transformative Innovations</p>
                                <h6 className='text-[55px] flex items-center'><span className='lg:pe-5 pe-2'>Our Talent Pool</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                                <p className='text-base'>Our accomplished team brings together a combined experience of over 60 years at the forefront of retail store digitization and Live Agent technologies. Proficient in crafting transformative solutions, our experts specialize in revolutionizing retail experiences through seamless digitization strategies.</p>
                                <p className='mt-2 text-base'>From deploying Live Agent systems that ensure real-time customer support to implementing cutting-edge technologies for store digitization, such as AR/VR applications, inventory management systems, and integrated e-commerce platforms, our team champions innovation.</p>
                                <p className='mt-2 text-base'>Their adeptness extends to harnessing data analytics for personalized customer interactions and optimizing customer journeys. With a keen eye on industry trends and a passion for redefining retail paradigms, our team remains dedicated to enhancing customer engagement and driving sustained business growth.</p>
                            </div>
                        </div>
                        <div className='lg:w-[40%] lg:mt-0 mt-7 relative h-full flex justify-center'>
                            <img src={PATH.img.TEAM} className="w-full" alt='features-img' />
                        </div>
                    </div>
                </div>
            </div>
            <RequestDemo
                RequesDemoHeading={'Ready to experience the power of Live Agent for yourself?'}
                RequestDemoMainHead={'Get LIVE Demo'}
                RequestDemoPara={`See firsthand how Live Agent enhances real-time interactions, personalizes support, and offers a range of valuable features like cart generation and age verification. Take the next step in elevating your customer experience. Click 'Book a Demo Now' and embark on a journey to superior support and increased customer satisfaction.`} />
            <Testimonial />
            <Footer />
        </React.Fragment >
    );
};

export default AboutUs;