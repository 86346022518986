import React from 'react';

const Textarea = ({ placeholder, name, onChange, error, value }) => {
    return (
        <div className={`flex flex-col gap-2 `}>
            <textarea
                value={value}
                type='text'
                name={name}
                onChange={onChange}
                className='py-3 border-b-[1px] border-gray-700 bg-transparent placeholder:text-base placeholder:text-[#545454]'
                placeholder={placeholder}
            />
            {error && <p className='text-red-500 text-sm'>{error}</p>}
        </div>
    );
};

export default Textarea;