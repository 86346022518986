import React, { useState } from 'react';
import Fundamentals from '../../component/FundamentalsContent.js';
import ViewArrow from '../../component/View_Arrow.js';
import CoreCompetency from '../../component/Core_Competency.js';
import RequestDemo from '../../component/RequestDemo.js';
import Testimonial from '../../component/Testimonial.js';
import Footer from '../../component/HOC/Footer.jsx';
import { PATH } from '../../globals/app-constants.js';
import { Link, useNavigate } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/24/solid';
import CompareSlider from '../../component/CompareSlider.js';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import Products from '../../component/Products.js';

const Home = () => {
    document.body.style.overflow = 'auto';
    const [compBg, setCompBg] = useState(PATH.img.FurnitureBg);
    const navigate = useNavigate();

    const scrollHandler = () => {
        const demoSection = document.getElementById('features');
        if (demoSection) {
            demoSection.scrollIntoView({
                behavior: 'smooth'
            });
        }
        setTimeout(() => {
            document.body.style.overflow = 'auto';
        }, 300);
    };


    return (
        <React.Fragment>
            <div className='w-full relative h-screen z-20'>
                {/* <video loop muted playsInline autoPlay className="w-full lg:h-full h-screen object-cover">
                    <source src={PATH.video.bgvideo} type="video/mp4" />
                </video> */}
                <CompareSlider className='w-full top-0 h-full z-20 overflow-hidden' ScrollTo={scrollHandler} />
            </div>
            <div id='features' className='bg-black px-5 lg:px-20 py-16'>
                <div className='container mx-auto lg:flex gap-y-9 items-start'>
                    <Fundamentals
                        ImgHidden={'lg:block hidden'}
                        DividerImg={PATH.img.divider}
                        className={'text-white'}
                        Icon={PATH.img.FundamentalIcon}
                        HeadingText={"Elevated Shopping Efficiency"}
                        ParaText={"CVI's innovative solutions simplify your shopping journey, ensuring swift selections and secure payments."}
                    />
                    <Fundamentals
                        ImgHidden={'lg:block hidden'}
                        DividerImg={PATH.img.divider}
                        className={'text-white'}
                        Icon={PATH.img.FundamentalIcon2}
                        HeadingText={"Personalized Assistance"}
                        ParaText={"live agents assist you in real time, offering expert guidance and product recommendations, making shopping smarter and more enjoyable."}
                    />
                    <Fundamentals
                        ImgHidden={'hidden'}
                        DividerImg={PATH.img.divider}
                        className={'text-white'}
                        Icon={PATH.img.FundamentalIcon3}
                        HeadingText={"Enhanced Shopping Confidence"}
                        ParaText={"Streamlined Checkout: With Cart Generation, you enjoy an efficient and secure checkout process, reducing uncertainties and enhancing your overall shopping experience."}
                    />
                </div>
            </div>


            {/* <div className='lg:px-20 px-5 py-24 bg-[#EFEFEF]'>
                <div className='container mx-auto flex flex-col gap-12'>
                    <div className='flex justify-center items-center'>
                        <div className='w-20 border-t-[1px] border-black'></div>
                        <h6 className='lg:px-5 px-2 text-2xl text-center'>We Worked With Global Largest Brands</h6>
                        <div className='w-20 border-t-[1px] border-black'></div>
                    </div>
                    <div className='flex flex-wrap justify-center gap-x-20 gap-y-10'>
                        <img src={PATH.img.brand1} width={97} className='object-contain' alt='brands' />
                        <img src={PATH.img.brand2} width={97} className='object-contain' alt='brands' />
                        <img src={PATH.img.brand3} width={97} className='object-contain' alt='brands' />
                        <img src={PATH.img.brand4} width={97} className='object-contain' alt='brands' />
                        <img src={PATH.img.brand5} width={97} className='object-contain' alt='brands' />
                        <img src={PATH.img.brand6} width={97} className='object-contain' alt='brands' />
                    </div>
                </div>
            </div> */}
            <div className='lg:px-20 flex flex-col gap-14 px-5 py-24'>
                <div className='container mx-auto'>
                    <div className='flex lg:flex-row flex-col-reverse items-center mt-10'>
                        <div className='lg:w-[40%] lg:mt-0 mt-7'>
                            <img src={PATH.img.LiveAgentHome} alt='features-img' />
                        </div>
                        <div className='lg:w-[60%] lg:ps-7'>
                            <div className='flex flex-col gap-3'>
                                <p className='text-base'>Behind the Brand: A Tale of Excellence.</p>
                                <h6 className='text-[42px] flex items-center'><div className='w-20 border-t-[1px] border-black'></div><span className='lg:ps-5 ps-2 font-medium banner_head'>About Us</span></h6>
                                <p className='text-[28px]'>Where real-time support meets customer delight</p>
                                <p className='text-base'>We are entering a new world of “Mixed Reality “– Live Commerce and Augmented reality will be intertwined to provide in-store experience sitting from home. Computer Vision is going to be cornerstone of Digital Transformation –ability to do more with less resources. Our vision is to Bridge E-Commerce and Physical Store with a digital Experience. High customer confidence results in a high-quality, low-regret purchase. Make the Retail Store 24/7 Accessible through their e-commerce. Combine Product with their Real-Life surrounding. Enable Live Commerce + Augmented reality.</p>
                            </div>
                            <ViewArrow
                                className={'mt-3'}
                                onClick={() => navigate('/aboutus')}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='gap-14 px-5 py-24 bg-slate-50'>
                <div className='container flex flex-col gap-16'>
                    <div className='flex flex-row'>
                        <div className='flex flex-col gap-2 items-left w-full'>
                            <p className='text-base'>Our Core</p>
                            <h6 className='text-[52px] gap-4 flex items-center'>
                                <span className='lg:pe-5 banner_head'>Competency</span>
                                <div className='w-20 border-t-[1px] border-black'></div>
                            </h6>
                            <p className='text-2xl'>A brief Outlook</p>
                        </div>
                    </div>
                    <div className='flex flex-col md:flex-row gap-4'>
                        <div className='flex flex-col gap-4 md:w-1/2'>
                            <div className='rounded-xl p-8 bg-slate-500 md:h-64' style={{ backgroundImage: `url(${PATH.img.AGENT_IMG})`, backgroundPosition: 'center', WebkitBackgroundSize: 'cover' }}>
                                <div className='flex flex-col gap-4 justify-between h-full'>
                                    <div className='w-1/2 flex flex-col gap-2'>
                                        <h6 className='text-sm'>Right Support Made Easy</h6>
                                        <h3 className='text-3xl font-semibold'>Live Agent</h3>
                                        <h5 className='text-xl'>Where real-time support meets customer delight</h5>
                                    </div>
                                    <Link className='flex items-center' to={'/live-agent'}>
                                        Learn About
                                        <i className={`h-10 w-10 rounded-full flex justify-center items-center`}>
                                            <ArrowRightIcon className='w-4' />
                                        </i>
                                    </Link>
                                </div>
                            </div>
                            <div className='flex flex-col md:flex-row gap-4 md:h-96'>
                                <div className='rounded-xl p-8 bg-slate-500 md:w-1/2' style={{ backgroundImage: `url(${PATH.img.CART_IMG})`, backgroundPosition: 'center', WebkitBackgroundSize: 'cover' }}>
                                    <div className='flex flex-col gap-4 h-full justify-between'>
                                        <div className='w-1/2 flex flex-col gap-2'>
                                            <h6 className='text-sm'>Celebrate the Convenience</h6>
                                            <h3 className='text-3xl font-semibold'>Cart Generation</h3>
                                            <h5 className='text-xl'>Effortless Product Selection</h5>
                                        </div>
                                        <Link className='flex items-center' to={'/cart-generation'}>
                                            Learn About
                                            <i className={`h-10 w-10 rounded-full flex justify-center items-center`}>
                                                <ArrowRightIcon className='w-4' />
                                            </i>
                                        </Link>
                                    </div>
                                </div>
                                <div className='rounded-xl p-8 bg-slate-500 md:w-1/2' style={{ backgroundImage: `url(${PATH.img.TD_IMD})`, backgroundPosition: 'bottom right', WebkitBackgroundSize: 'cover' }}>
                                    <div className='flex flex-col gap-4 h-full justify-between'>
                                        <div className='w-1/2 flex flex-col gap-2'>
                                            <h6 className='text-sm'>Visualize with Confidence</h6>
                                            <h3 className='text-3xl font-semibold'>3D View</h3>
                                            <h5 className='text-xl'>360-Degree Product Exploration</h5>
                                        </div>
                                        <Link className='flex items-center' to={'/3d-view'}>
                                            Learn About
                                            <i className={`h-10 w-10 rounded-full flex justify-center items-center`}>
                                                <ArrowRightIcon className='w-4' />
                                            </i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-4 md:w-1/2'>
                            <div className='rounded-xl p-8 bg-slate-500 flex-grow' style={{ backgroundImage: `url(${PATH.img.AR_IMG})`, backgroundPosition: 'bottom right', WebkitBackgroundSize: 'cover' }}>
                                <div className='flex flex-col gap-4 h-full justify-between'>
                                    <div className='w-1/2 flex flex-col gap-2'>
                                        <h6 className='text-sm'>Immersive Shopping Experience</h6>
                                        <h3 className='text-3xl font-semibold'>Augmented Reality</h3>
                                        <h5 className='text-xl'>Higher Product Perspective</h5>
                                    </div>
                                    <Link className='flex items-center' to={'/augmented-reality'}>
                                        Learn About
                                        <i className={`h-10 w-10 rounded-full flex justify-center items-center`}>
                                            <ArrowRightIcon className='w-4' />
                                        </i>
                                    </Link>
                                </div>
                            </div>
                            <div className='rounded-xl p-8 bg-slate-500' style={{ backgroundImage: `url(${PATH.img.LANG_IMG})`, backgroundPosition: 'center', WebkitBackgroundSize: 'cover' }}>
                                <div className='flex flex-col gap-4'>
                                    <div className='w-1/2 flex flex-col gap-2'>
                                        <h6 className='text-sm'>Right Support Made Easy</h6>
                                        <h3 className='text-3xl font-semibold'>Small Language Model</h3>
                                        <h5 className='text-xl'>Where real-time support meets customer delight</h5>
                                    </div>
                                    <Link className='flex items-center' to={'/small-language'}>
                                        Learn About
                                        <i className={`h-10 w-10 rounded-full flex justify-center items-center`}>
                                            <ArrowRightIcon className='w-4' />
                                        </i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='gap-14 px-5 py-24 bg-slate-50'>
                <div className='container mx-auto flex flex-col gap-16'>
                    <div className='flex flex-row'>
                        <div className='flex flex-col gap-2 items-center w-full'>
                            <p className='text-base'>Our Core</p>
                            <h6 className='text-[52px] gap-4 flex items-center'>
                                <div className='w-20 border-t-[1px] border-black'></div>
                                <span className='lg:pe-5 banner_head'>Competency</span>
                                <div className='w-20 border-t-[1px] border-black'></div>
                            </h6>
                            <p className='text-2xl'>A brief Outlook</p>
                        </div>
                    </div>
                    <div className='grid grid-cols-3 gap-8 justify-items-center'>
                        <Products
                            title={'Live Agent'}
                            subtitle={'Where real-time support meets customer delight'}
                            prefixTitle={'Right Support Made Easy'}
                            icon={PATH.img.LIVE_AGENT_ICON}
                            content={'Your gateway to unparalleled customer support in retail and e-commerce. Our platform connects you with live agents who offer real-time solutions through chat, video calls, and personalized assistance.'}
                            path={'/live-agent'}
                            varient={'dark'}
                        />
                        <Products
                            title={'Augmented Reality'}
                            subtitle={'Higher Product Perspective'}
                            prefixTitle={'Immersive Shopping Experience'}
                            icon={PATH.img.AR_ICON}
                            content={'Unlock a revolutionary way to shop with Augmented Reality (AR) at CVI. Our AR technology takes your shopping experience to the next level. Visualize products from every perspective, examine intricate details up close, and seamlessly integrate them into your living space for the perfect fit.'}
                            path={'/augmented-reality'}
                        />
                        <Products
                            title={'3D View'}
                            subtitle={'360-Degree Product Exploration'}
                            prefixTitle={'Visualize with Confidence'}
                            icon={PATH.img.TD_ICON}
                            content={'CVI introduces 3D View, a remarkable feature that elevates your shopping experience to new heights. With 3D View, you can explore our products from every angle, delve into specific details, and even place them in your space to ensure the perfect fit before making your purchase."'}
                            path={'/3d-view'}
                        />
                        <Products
                            title={'Cart Generation'}
                            subtitle={'Effortless Product Selection'}
                            prefixTitle={'Celebrate the Convenience'}
                            icon={PATH.img.CART_ICON}
                            content={`Where we make shopping an absolute breeze. With Cart Generation, you get the personal touch of our expert agents assisting you in real time. Simply tap the 'Cart' icon, and you'll have our guidance every step of the way, from product selection to secure payments.`}
                            path={'/cart-generation'}
                        />
                        <Products
                            title={'Small Language'}
                            subtitle={'Where real-time support meets customer delight'}
                            prefixTitle={'Right Support Made Easy'}
                            icon={PATH.img.SMALL_LANG_ICON}
                            content={'Your gateway to unparalleled customer support in retail and e-commerce. Our platform connects you with live agents who offer real-time solutions through chat, video calls, and personalized assistance.'}
                            path={'/'}
                        />
                    </div>
                </div>
            </div> */}
            {/* <div className='lg:px-20 flex flex-col gap-14 px-5 py-24'>
                <div className='container mx-auto'>
                    <div className='sm:flex justify-between items-center'>
                        <div className='flex flex-col gap-2'>
                            <p className='text-base'>Future of Retail Shopping</p>
                            <h6 className='text-[52px] flex items-center'><span className='lg:pe-5 pe-2 banner_head'>Features</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                            <p className='text-2xl'>A brief Outlook</p>
                        </div>
                        <div>
                            <div className='border-[1px] rounded-3xl flex justify-center md:gap-8 gap-3 py-3 px-4 lg:px-8 lg:mt-0 mt-6'>
                                <p onClick={() => setCount(1)} className={count1 === 1 ? 'text-black lg:text-base  cursor-pointer text-center' : 'text-[#D9D9D9] lg:text-base  cursor-pointer text-center'}>Live Agent</p>
                                <p onClick={() => setCount(2)} className={count1 === 2 ? 'text-black lg:text-base  cursor-pointer text-center' : 'text-[#D9D9D9] lg:text-base  cursor-pointer text-center'}>Augmented Reality</p>
                                <p onClick={() => setCount(3)} className={count1 === 3 ? 'text-black lg:text-base  cursor-pointer text-center' : 'text-[#D9D9D9] lg:text-base  cursor-pointer text-center'}>3D View</p>
                                <p onClick={() => setCount(4)} className={count1 === 4 ? 'text-black lg:text-base  cursor-pointer text-center' : 'text-[#D9D9D9] lg:text-base  cursor-pointer text-center'}>Cart Generation</p>
                            </div>
                        </div>
                    </div>
                    {count1 === 1 && <>
                        <div className='flex lg:flex-row flex-col-reverse items-center mt-10'>
                            <div className='lg:w-[40%] lg:mt-0 mt-7'>
                                <img src={PATH.img.LiveAgentHome} alt='features-img' />
                            </div>
                            <div className='lg:w-[60%] lg:ps-7'>
                                <div className='flex flex-col gap-3'>
                                    <p className='text-base'>Right Support Made Easy</p>
                                    <h6 className='text-[42px] flex items-center'><div className='w-20 border-t-[1px] border-black'></div><span className='lg:ps-5 ps-2 font-medium banner_head'>Live Agent</span></h6>
                                    <p className='text-[28px]'>Where real-time support meets customer delight</p>
                                    <p className='text-base'>Welcome to Live Agent, your gateway to unparalleled customer support in retail and e-commerce. Our platform connects you with live agents who offer real-time solutions through chat, video calls, and personalized assistance. We empower your business with 24/7 support, immersive AR experiences, and streamlined payments, enhancing customer satisfaction and driving profitability.</p>
                                    <p className='text-base'>Elevate your customer service and transform your online retail experience with Live Agent.</p>
                                </div>
                                <ViewArrow
                                    className={'mt-3'}
                                    onClick={() => navigate('/live-agent')}
                                />
                            </div>
                        </div>
                    </>}
                    {count1 === 2 && <>
                        <div className='flex lg:flex-row flex-col-reverse items-center mt-10'>
                            <div className='lg:w-[40%] lg:mt-0 mt-7'>
                                <img src={PATH.img.FeatureImg} alt='features-img' />
                            </div>
                            <div className='lg:w-[60%] lg:ps-7'>
                                <div className='flex flex-col gap-3'>
                                    <p className='text-base'>Immersive Shopping Experience</p>
                                    <h6 className='text-[42px] flex items-center'><div className='w-20 border-t-[1px] border-black'></div><span className='lg:ps-5 ps-2 font-medium'>Augmented Reality</span></h6>
                                    <p className='text-[28px]'>Higher Product Perspective</p>
                                    <p className='text-base'>Unlock a revolutionary way to shop with Augmented Reality (AR) at CVI. Our AR technology takes your shopping experience to the next level.</p>
                                    <p className='text-base'>Visualize products from every perspective, examine intricate details up close, and seamlessly integrate them into your living space for the perfect fit. Welcome to a new era of interactive, informed shopping.</p>
                                </div>
                                <ViewArrow
                                    className={'mt-3'}
                                    onClick={() => navigate('/augmented-reality')}
                                />
                            </div>
                        </div></>}
                    {count1 === 3 && <>
                        <div className='flex lg:flex-row flex-col-reverse items-center mt-10'>
                            <div className='lg:w-[40%] lg:mt-0 mt-7'>
                                <img src={PATH.img.View3d} alt='features-img' />
                            </div>
                            <div className='lg:w-[60%] lg:ps-7'>
                                <div className='flex flex-col gap-3'>
                                    <p className='text-base'>Visualize with Confidence</p>
                                    <h6 className='text-[42px] flex items-center'><div className='w-20 border-t-[1px] border-black'></div><span className='lg:ps-5 ps-2 font-medium'>3D View</span></h6>
                                    <p className='text-[28px]'>360-Degree Product Exploration</p>
                                    <p className='text-base'>CVI introduces 3D View, a remarkable feature that elevates your shopping experience to new heights. With 3D View, you can explore our products from every angle, delve into specific details, and even place them in your space to ensure the perfect fit before making your purchase."</p>
                                    <p className='text-base'>If you have any additional information or specific details to include, please let me know, and I'll be happy to expand on it.</p>
                                </div>
                                <ViewArrow
                                    className={'mt-3'}
                                    onClick={() => navigate('/3d-view')}
                                />
                            </div>
                        </div></>}
                    {count1 === 4 && <>
                        <div className='flex lg:flex-row flex-col-reverse items-center mt-10'>
                            <div className='lg:w-[40%] lg:mt-0 mt-7'>
                                <img src={PATH.img.CartGeneration} alt='features-img' />
                            </div>
                            <div className='lg:w-[60%] lg:ps-7'>
                                <div className='flex flex-col gap-3'>
                                    <p className='text-base'>Celebrate the Convenience</p>
                                    <h6 className='text-[42px] flex items-center'><div className='w-20 border-t-[1px] border-black'></div><span className='lg:ps-5 ps-2 font-medium'>Cart Generation</span></h6>
                                    <p className='text-[28px]'>Effortless Product Selection</p>
                                    <p className='text-base'>Welcome to CVI's Cart Generation, where we make shopping an absolute breeze. With Cart Generation, you get the personal touch of our expert agents assisting you in real time. Simply tap the 'Cart' icon, and you'll have our guidance every step of the way, from product selection to secure payments."</p>
                                    <p className='text-base'>Feel free to provide additional information or specific details you'd like to include in the content, and I'll be happy to expand on it.</p>
                                </div>
                                <ViewArrow
                                    className={'mt-3'}
                                    onClick={() => navigate('/cart-generation')}
                                />
                            </div>
                        </div></>}
                </div>
            </div> */}
            {/* <div className='w-full relative h-full z-20'>
                <div className='bg-gradient-to-b from-black from-[-4%] via-transparent to-transparent w-full h-full absolute top-0 opacity-100'></div>
                <CarouselComponent>
                    <BannerContent
                        img={PATH.img.SmallLanguage}
                        BannerHead={'Small Language Model'}
                        BannerText={'Small language models can be employed for a variety of natural language processing tasks. We support multiple languages to help your customers understand your product.'}
                        btnValue={'Request Demo'} />
                    <BannerContent
                        img={PATH.img.VirtualReality}
                        BannerHead={'Virtual Reality'}
                        BannerText={'Shoppers can visualize products from all angles, examine intricate details, and even place items in their own living spaces.'}
                        btnValue={'Request Demo'} />
                    <BannerContent
                        img={PATH.img.CartGen}
                        BannerHead={'Cart Generation'}
                        BannerText={'Say goodbye to the complexities of online shopping and let our Cart Generation feature guide you to a future of streamlined selections, secure payments, and unparalleled convenience.'}
                        btnValue={'Request Demo'} />
                </CarouselComponent>
            </div> */}
            <div className='relative text-white lg:h-screen px-5 lg:px-20 py-16'>
                <div className='container mx-auto h-full'>
                    <img src={compBg} className='absolute top-0 left-0 w-full h-full object-cover' alt='Furniture-img' />
                    <div className='bg-black opacity-60 absolute top-0 left-0 z-10 w-full h-full'></div>
                    <div className='relative z-30 h-full flex flex-col gap-3 justify-center py-12'>
                        <div>
                            <p className='text-[32px]'>Supported</p>
                            <h6 className='text-[52px] flex items-center'><span className='lg:pe-5 pe-2 banner_head'>Business Fields</span><div className='w-20 border-t-[1px] border-white'></div></h6>
                        </div>
                        <div className='grid lg:grid-cols-3 h-full lg:place-content-center'>
                            <CoreCompetency
                                onMouseOver={() => setCompBg(PATH.img.FurnitureBg)}
                                className={'lg:border-t-0 lg:border-l-0 h-full'}
                                ImgPosition={PATH.img.FURNITURE_ICON}
                                CompetencyName={'Furniture'}
                            />
                            <CoreCompetency
                                onMouseOver={() => setCompBg(PATH.img.comp_suite)}
                                className={'lg:border-t-0 lg:border-l-0 h-full'}
                                ImgPosition={PATH.img.CLOTH_ICON}
                                CompetencyName={'Cloths'}
                            />
                            <CoreCompetency
                                onMouseOver={() => setCompBg(PATH.img.comp_shoe)}
                                className={'lg:border-t-0 lg:border-l-0 lg:border-r-0 h-full'}
                                ImgPosition={PATH.img.SHOES_ICON}
                                CompetencyName={'Shoes'}
                            />
                            <CoreCompetency
                                onMouseOver={() => setCompBg(PATH.img.comp_bag)}
                                className={'lg:border-t-0 lg:border-l-0 lg:border-b-0 h-full'}
                                ImgPosition={PATH.img.BAGS_ICON}
                                CompetencyName={'Bags'}
                            />
                            <CoreCompetency
                                onMouseOver={() => setCompBg(PATH.img.comp_gad)}
                                className={'lg:border-t-0 lg:border-l-0 lg:border-r-0 lg:border-b-0 h-full'}
                                ImgPosition={PATH.img.GADGETS_ICON}
                                CompetencyName={'Gadgets'}
                            />
                            <CoreCompetency
                                onMouseOver={() => setCompBg(PATH.img.comp_wine)}
                                className={'lg:border-t-0 lg:border-r-0 lg:border-b-0 h-full'}
                                ImgPosition={PATH.img.INDUS_ICON}
                                CompetencyName={'& More'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <RequestDemo
                RequesDemoHeading={'Explore, Experience, Evolve, Engage'}
                RequestDemoMainHead={'Revolutionize Your Shopping Journey'}
                RequestDemoPara={`Ready to unlock the full potential of our groundbreaking solutions, spanning Augmented Reality, 3D View, Cart Generation, and Live Agent support? Dive into the future with a personalized demo, guided by our experts. Discover the extraordinary features that will transform your shopping experience.`}
                RequestDemoPara2={`Request a demo today and embrace a brighter, more efficient future, where innovation redefines your retail adventure.`}
            />
            <div className='lg:px-20 px-5 py-24 bg-white'>
                <div className='container mx-auto flex gap-16'>
                    <div className='grid lg:grid-cols-3 gap-16'>
                        <div className='flex flex-col gap-4'>
                            <h6 className='text-lg'>Help now</h6>
                            <div className='flex justify-left items-center gap-4'>
                                <h6 className='text-5xl banner_head'>Features and Support</h6>
                                <div className='w-20 border-t-[1px] border-black'></div>
                            </div>
                            {/* <p>Aut qui inventore quaerat perspiciatis est aliquam. Iusto illo doloribus dicta. Ullam aut quo enim dolorum consequatur mollitia. Et quia veritatis esse dolores consequatur consequatur delectus. Cupiditate sed quaerat iste ut libero ut blanditiis temporibus. Corrupti consectetur rem.</p> */}
                        </div>
                        <div className='col-span-2 overflow-x-auto'>
                            <table className='w-full'>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className='py-4 px-8 border-x'>
                                            <h3 className='font-normal text-2xl'>Brand Owners</h3>
                                            <h6 className='font-normal'>(Brand operators)</h6>
                                        </th>
                                        <th className='py-4 px-8'>
                                            <h3 className='font-normal text-2xl'>Channel Partners</h3>
                                            <h6 className='font-normal'>(Service Desk operators)</h6>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='odd:bg-slate-100'>
                                        <td className='py-2 px-8'>Manage video conferencing infrastructure</td>
                                        <td className='py-2 px-8 text-center border-x'><CheckIcon className='w-4 mx-auto' /></td>
                                        <td className='py-2 px-8 text-center'><CheckIcon className='w-4 mx-auto' /></td>
                                    </tr>
                                    <tr className='odd:bg-slate-100'>
                                        <td className='py-2 px-8'>How to use catalog and guidelines for Process Trainers</td>
                                        <td className='py-2 px-8 text-center border-x'><CheckIcon className='w-4 mx-auto' /></td>
                                        <td className='py-2 px-8 text-center'><CheckIcon className='w-4 mx-auto' /></td>
                                    </tr>
                                    <tr className='odd:bg-slate-100'>
                                        <td className='py-2 px-8'>New member onboarding and training</td>
                                        <td className='py-2 px-8 text-center border-x'><CheckIcon className='w-4 mx-auto' /></td>
                                        <td className='py-2 px-8 text-center'></td>
                                    </tr>
                                    <tr className='odd:bg-slate-100'>
                                        <td className='py-2 px-8'>Security Audits</td>
                                        <td className='py-2 px-8 text-center border-x'><CheckIcon className='w-4 mx-auto' /></td>
                                        <td className='py-2 px-8 text-center'></td>
                                    </tr>
                                    <tr className='odd:bg-slate-100'>
                                        <td className='py-2 px-8'>Quality Control</td>
                                        <td className='py-2 px-8 text-center border-x'><CheckIcon className='w-4 mx-auto' /></td>
                                        <td className='py-2 px-8 text-center'></td>
                                    </tr>
                                    <tr className='odd:bg-slate-100'>
                                        <td className='py-2 px-8'>Use as multi tenant</td>
                                        <td className='py-2 px-8 text-center border-x'></td>
                                        <td className='py-2 px-8 text-center'><CheckIcon className='w-4 mx-auto' /></td>
                                    </tr>
                                    <tr className='odd:bg-slate-100'>
                                        <td className='py-2 px-8'>Agent recruitment and payroll processing</td>
                                        <td className='py-2 px-8 text-center border-x'></td>
                                        <td className='py-2 px-8 text-center'></td>
                                    </tr>
                                    <tr className='odd:bg-slate-100'>
                                        <td className='py-2 px-8'>End customer Feedback audit</td>
                                        <td className='py-2 px-8 text-center border-x'></td>
                                        <td className='py-2 px-8 text-center'></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Testimonial />
            <Footer />
        </React.Fragment>
    );
};

export default Home;