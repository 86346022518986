import React from 'react'

const Button = ({ TableHeadText, Price }) => {
    return (
        <div className='font-normal text-center flex flex-col gap-4 pt-8'>
            <p className='text-2xl'>{TableHeadText}</p>
            <p className='mt-1 text-[22px]'><span className='text-[42px]'>${Price}</span>/Seat</p>
            <div className='w-full'>
                <button className='text-base w-auto border-black border-[1px] px-3 py-1 rounded-md mt-2'>Let’s Start</button>
            </div>
        </div>
    )
}

export default Button
