import Menu from '../asset/img/menu.svg';
import LogoWhite from '../asset/img/logo.svg';
import LogoBlack from '../asset/img/LogoBlack.svg';
import MenuBlack from '../asset/img/black_menu.svg';
import LoginIcon from '../asset/img/login.svg';
import LoginBlack from '../asset/img/LoginBlack.svg';
import FundamentalIcon from '../asset/img/fundamental_icon1.svg';
import FundamentalIcon2 from '../asset/img/fundamental_icon2.svg';
import FundamentalIcon3 from '../asset/img/fundamental_icon3.svg';
import WatchVideo from '../asset/img/Watch_video.svg';
import brand1 from '../asset/img/brand1.png';
import brand2 from '../asset/img/brand2.png';
import brand3 from '../asset/img/brand3.png';
import brand4 from '../asset/img/brand4.png';
import brand5 from '../asset/img/brand5.png';
import brand6 from '../asset/img/brand6.png';
import FeatureImg from '../asset/img/features_img.png';
import Arrow from '../asset/img/arrow.svg';
import FooterLogo from '../asset/img/footer_logo.svg';
import Facebook from '../asset/img/facebook.svg';
import Twitter from '../asset/img/twitter.svg';
import Instagram from '../asset/img/instagram.svg';
import Linkedin from '../asset/img/linkedin.svg';
import Profile from '../asset/img/profile.jpg';
import DottedDesign from '../asset/img/dotted_bg.svg';
import DottedDesign1 from '../asset/img/dotted_bg1.svg';
import FurnitureBg from '../asset/img/Furniture_bg.jpg';
import comp_suite from '../asset/img/comp-suit-bg.jpg';
import comp_wine from '../asset/img/comp-wine-bg.jpg';
import comp_bag from '../asset/img/comp-bag-bg.jpg';
import comp_gad from '../asset/img/comp-gad-bg.jpg';
import comp_shoe from '../asset/img/comp-shoe-bg.jpg';
import Furniture from '../asset/img/Furniture.svg';
import Cloth from '../asset/img/Cloths.svg';
import Wine from '../asset/img/wine.svg';
import Shoes from '../asset/img/Shoes.svg';
import Bags from '../asset/img/Bags.svg';
import Gadgets from '../asset/img/Gadgets.svg';
import ARImg from '../asset/img/AR_img.png';
import AboutDesign from '../asset/img/about_design.png';
import TeamImg from '../asset/img/team_img.png';
import ArIcon1 from '../asset/img/ar_icon1.svg';
import ArIcon2 from '../asset/img/ar_icon2.svg';
import ArIcon3 from '../asset/img/ar_icons3.svg';
import ComingSoonImg from '../asset/img/Comingsoon_img.png';
import LiveAgentHome from '../asset/img/live_agent_home.png';
import CartGeneration from '../asset/img/cart_generation_home.png';
import View3d from '../asset/img/3d_view_home.png';
import LiveAgentDetail from '../asset/img/live_agent_detail.png';
import View3dDetail from '../asset/img/3d_view_detail.png';
import CartGenerationDetail from '../asset/img/cart_generation_detail.png';
import bgvideo from '../asset/video/video_bg.mp4';
import ARvideo from '../asset/video/augmented_video.mp4';
import Video3d from '../asset/video/3dview_video.mp4';
import LiveAgentVideo from '../asset/video/live_agent_video.mp4';
import CartGenerationVideo from '../asset/video/cartgeneration_video.mp4';
import divider from '../asset/img/fundamental_design.svg';
import SendIcon from '../asset/img/send.svg';
import CallPerson from '../asset/img/CallPerson.svg';
import ChatUser from '../asset/img/chatUser.svg';
import LineBlack from '../asset/img/line_black.svg';
import Profile2 from '../asset/img/profile2.png';
import VideoCommerce from '../asset/img/video-commerce-1.jpg';
import SmallLanguage from '../asset/img/small-lang.jpg';
import VirtualReality from '../asset/img/augmented-reality.jpg';
import CartGen from '../asset/img/qr-code.jpg';
import AR_MORE from '../asset/img/ar-more.png';
import AR_PLACEMENT from '../asset/img/ar-placement.jpg';
import OLD_COMPARE from '../asset/video/banner1.mp4';
import NEW_COMPARE from '../asset/video/Banner2.mp4';
import TEAM from '../asset/img/about_theTeam.png';
import AR_ICON from '../asset/img/icon/ar.svg';
import LIVE_AGENT_ICON from '../asset/img/icon/live-agent.svg';
import TD_ICON from '../asset/img/icon/3d.svg';
import CART_ICON from '../asset/img/icon/cart.svg';
import SMALL_LANG_ICON from '../asset/img/icon/language.svg';
import TD_IMD from '../asset/img/products/3d.jpg';
import AR_IMG from '../asset/img/products/ar.jpg';
import CART_IMG from '../asset/img/products/cart.jpg';
import LANG_IMG from '../asset/img/products/language.jpg';
import AGENT_IMG from '../asset/img/products/live-agent.jpg';
import CLOTH_ICON from '../asset/img/icon/cloths.svg';
import FURNITURE_ICON from '../asset/img/icon/furniture.svg';
import SHOES_ICON from '../asset/img/icon/shoes.svg';
import BAGS_ICON from '../asset/img/icon/bags.svg';
import GADGETS_ICON from '../asset/img/icon/gadgets.svg';
import INDUS_ICON from '../asset/img/icon/indus.svg';

export const PATH = {
    img: {
        Menu, LogoWhite, LoginIcon, FundamentalIcon, FundamentalIcon2, FundamentalIcon3, WatchVideo,
        brand1, brand2, brand3, brand4, brand5, brand6, FeatureImg, Arrow, FooterLogo, Facebook,
        Twitter, Instagram, Linkedin, Profile, DottedDesign, DottedDesign1, FurnitureBg, Furniture, Cloth,
        Wine, Shoes, Bags, Gadgets, ARImg, AboutDesign, TeamImg, ArIcon1, ArIcon2, ArIcon3, ComingSoonImg, MenuBlack, LoginBlack, LogoBlack,
        LiveAgentHome, CartGeneration, View3d, LiveAgentDetail, View3dDetail, CartGenerationDetail, comp_suite, comp_wine, comp_bag, comp_gad, comp_shoe,
        divider, SendIcon, CallPerson, ChatUser, LineBlack, Profile2, VideoCommerce, SmallLanguage, VirtualReality, CartGen, AR_MORE, AR_PLACEMENT, TEAM, AR_ICON, LIVE_AGENT_ICON, TD_ICON, CART_ICON, SMALL_LANG_ICON, TD_IMD, AR_IMG, CART_IMG, LANG_IMG, AGENT_IMG, CLOTH_ICON, FURNITURE_ICON, SHOES_ICON, BAGS_ICON, GADGETS_ICON, INDUS_ICON
    },

    video: {
        bgvideo, ARvideo, Video3d, LiveAgentVideo, CartGenerationVideo, OLD_COMPARE, NEW_COMPARE
    }
};

export const ROUTES = {
    HOME: '/',
    AUGMENTED_REALITY: '/detail/augmented-reality',
    LIVE_AGENT: '/detail/live-agent',
    VIEW_3D: '/detail/view-3d',
    CART_GENERATION: '/detail/cart-generation',
    ABOUTUS: '/aboutus',
    PRICING: '/pricing',
    CONTACTUS: '/contactus',
    PRIVACY_POLICY: '/privacy-policy'
};