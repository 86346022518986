import React, { useEffect, useState } from 'react';
import { Outlet } from "react-router-dom";
import Header from "./Header";

const Layout = () => {
    const [isHome, setIsHome] = useState(true);

    useEffect(() => {
        let previousScrollPosition = 0;
        let currentScrollPosition = 0;
        window.addEventListener('scroll', function (e) {
            currentScrollPosition = window.scrollY;
            // console.log("listening", currentScrollPosition);
            if (currentScrollPosition > 100) {
                setIsHome(false);
            } else {
                setIsHome(true);
            }
            if (previousScrollPosition - currentScrollPosition < 0) {
                // setShow(false);
            } else if (previousScrollPosition - currentScrollPosition > 0) {
                // setShow(true);
            }
            previousScrollPosition = currentScrollPosition;
        });
    }, []);
    return (
        <div id="header_scroll" className='w-full overflow-y-auto relative'>
            <Header isHome={isHome} />
            <Outlet />
        </div>
    );
};

export default Layout;
