import React from 'react';

const Input = ({ placeholder, name, onChange, error, type, value }) => {
    // console.log(error);
    return (
        <div className={`flex flex-col gap-2 `}>
            <input
                name={name}
                type={type ? type : 'text'}
                onChange={(e) => onChange(e)}
                value={value}
                className='py-3 border-b-[1px] border-gray-700 bg-transparent placeholder:text-base placeholder:text-[#545454]'
                placeholder={placeholder}
            />
            {error && <p className="text-red-500 text-sm">{error}</p>}
        </div>
    );
};

export default Input;