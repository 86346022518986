import React from "react";
import { Routes, Route } from 'react-router-dom';
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import './asset/css/style.scss';
import Home from '../src/pages/Home';
import AugmentedReality from "./pages/Detail/Augmented_Reality";
import Layout from "./component/HOC/Layout";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Pricing from "./pages/Pricing";
import LiveAgent from "./pages/Detail/Live_Agent";
import CartGeneration from "./pages/Detail/Cart_Generation";
import View3d from "./pages/Detail/3d_View";
import PrivacyPolicy from "./pages/Privacy_Policy'";
import SmallLanguage from "./pages/Detail/SmallLanguage";
// import Login from "./component/Login";
// import Dashboard from "./component/Dashboard";

const App = () => {

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Home key="home" />} />
        <Route path="/augmented-reality" element={<AugmentedReality />} />
        <Route path="/3d-view" element={<View3d />} />
        <Route path="/live-agent" element={<LiveAgent />} />
        <Route path="/cart-generation" element={<CartGeneration />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/small-language" element={<SmallLanguage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Route>
    </Routes>
  );
};

export default App;
