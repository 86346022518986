import React from 'react'

const Fundamentals = ({ Icon, HeadingText, ParaText, className, ImgHidden, DividerImg }) => {
    return (
        <div className='relative flex items-center h-[94px] lg:mt-0 mt-8 first:mt-0 lg:gap-0 gap-4 lg:w-1/3 lg:px-8 first:ps-0 last:pe-0'>
            <img src={DividerImg} className={`absolute right-0 ${ImgHidden}`} alt='divider-design' />
            <div className={`flex flex-grow items-center ${className}`}>
                <div className='lg:w-[14%] w-[12%]'>
                    <img src={Icon} width={30} alt='icon' />
                </div>
                <div className='lg:w-[86%] w-[88%]'>
                    <h6 className='text-[20px] font-medium flex-grow'>{HeadingText}</h6>
                    <p className='text-sm flex-grow font-medium mt-1'>{ParaText}</p>
                </div>
            </div>
        </div>
    )
}

export default Fundamentals