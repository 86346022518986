import React, { useEffect, useState } from 'react';
import { PATH } from '../globals/app-constants';
import Input from '../component/input.js';
import TextArea from '../component/Textarea.js';
import Button from '../component/Button.js';
import { Validation, isValidEmail, validateMaxName, validateMobileNumnber } from '../utils/validation.js';
var nospecial = /^[^*|\":<>[\]{}`\\()';@&$]+$/;

const initialState = {
    name: "",
    phone: "",
    company: "",
    email: "",
    message: "",
};
const errorMsg = {
    name: "",
    phone: "",
    company: "",
    email: "",
    message: "",
};
const Fundamentals = ({ RequesDemoHeading, RequestDemoMainHead, RequestDemoPara, RequestDemoPara2 }) => {
    const [formDetails, setFormDetails] = useState(initialState);
    const [errors, setErrors] = useState(errorMsg);
    const [emailSuccess, setEmailSuccess] = useState(false);
    const [emailError, setEmailError] = useState(false);

    const handleChange = ({ name, value }) => {
        setFormDetails({ ...formDetails, [name]: value });
        let validatedError = Validation({ [name]: value }, ["name", "company", "phone", "email", "message"]);
        if (validatedError) {
            setErrors({ ...errors, ...validatedError });
        }
    };

    const handleNumberInput = (event) => {
        var txt = nospecial.test(event.target.value);
        if (!txt) {
            if (event.target.value == "" && formDetails?.phone == "") {
                event.target.value = event.target.value;
            } else if (event.target.value == "" && formDetails?.phone !== "") {
                if (formDetails?.phone?.length > 1) {
                    event.target.value = formDetails?.phone ?? "";
                } else if (formDetails?.phone?.length == 1 && event.target.value !== "") {
                    event.target.value = formDetails?.phone;
                } else if (formDetails?.phone !== "") {
                    if (event.target.value !== "") {
                        event.target.value = formDetails?.phone;
                    } else {
                        event.target.value = "";
                    }
                } else {
                    event.target.value = "";
                }
            } else {
                event.target.value = formDetails?.phone;
            }
        } else {
            event.target.value = event.target.value;
        }
    };

    const handleEmailSuccess = (data) => {
        setFormDetails(initialState);
        setEmailSuccess(data?.success);
    };

    const handleSubmitValidation = () => {
        setErrors(() => ({ ...errorMsg }));
        let isFormError = false;
        if (!formDetails?.name) {
            setErrors((prevErrors) => ({ ...prevErrors, name: "Please enter the name" }));
            isFormError = true;
        }
        if (!validateMaxName(formDetails?.name)) {
            setErrors((prevErrors) => ({ ...prevErrors, name: "Please enter the valid name" }));
            isFormError = true;
        }
        if (!formDetails?.company) {
            setErrors((prevErrors) => ({ ...prevErrors, company: "Please enter the company name" }));
            isFormError = true;
        }
        if (!formDetails?.phone) {
            setErrors((prevErrors) => ({ ...prevErrors, phone: "Please enter the phone number" }));
            isFormError = true;
        }
        if (!validateMobileNumnber(formDetails?.phone)) {
            setErrors((prevErrors) => ({ ...prevErrors, phone: "Please enter the valid phone number" }));
            isFormError = true;
        }
        if (!formDetails?.email) {
            setErrors((prevErrors) => ({ ...prevErrors, email: "Please enter the email address" }));
            isFormError = true;
        }
        if (!isValidEmail(formDetails?.email)) {
            setErrors((prevErrors) => ({ ...prevErrors, email: "Please enter the valid email address" }));
            isFormError = true;
        }
        if (!formDetails?.message) {
            setErrors((prevErrors) => ({ ...prevErrors, message: "Please enter the message" }));
            isFormError = true;
        }
        if (isFormError) {
            return;
        }

        const data = { ...formDetails };
        fetch(`https://api.cvi.ai/liveAgent/mail/trigger`, {
            method: "POST",
            headers: {
                Authorization: "Basic cHJvZ2xpbnQ6dG5pbGdvcnAzMjE=",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => handleEmailSuccess(data))
            .catch(() => setEmailError(true));
    };

    return (
        <div id='request-demo' className='relative overflow-hidden  bg-black text-white lg:px-20 px-5 lg:py-16 py-14'>
            <div className='container mx-auto lg:flex'>
                <img src={PATH.img.DottedDesign1} className='absolute left-0 top-32 w-80' alt='bg-design' />
                <div className='absolute lg:block hidden bottom-[-20px] right-64 w-60 h-60 border-[1px] rounded-full'></div>
                <div className='absolute lg:block hidden left-[-210px] top-36 w-60 h-60 border-[1px] rounded-full'></div>
                <div className='relative z-20 flex lg:w-2/3 py-12 lg:pe-14'>
                    <div className='lg:w-auto w-full flex flex-col gap-3'>
                        <p className='text-2xl'>{RequesDemoHeading}</p>
                        <h6 className='banner_head text-[52px] flex items-center'><span className='lg:pe-5 pe-2 font-medium'>{RequestDemoMainHead}</span><div className='w-20 border-t-[1px] border-white'></div></h6>
                        <p className='text-base mt-8'>{RequestDemoPara}</p>
                        <p className='text-base mt-8'>{RequestDemoPara2}</p>
                        <div className='grid grid-cols-2 mt-12'>
                            <div>
                                <p className='text-[52px] font-medium'>325K+</p>
                                <p className='text-base mt-1'>Customers</p>
                            </div>
                            <div>
                                <p className='text-[52px] font-medium'>1236K+</p>
                                <p className='text-base mt-1'>Implementations</p>
                            </div>
                        </div>
                        <div className='w-full'>
                            <div className='inline-flex lg:flex-row flex-col lg:gap-0 gap-4 lg:w-auto w-full bg-[#1C1C1C] rounded-xl py-[12px] mt-8'>
                                <div className='flex items-center lg:px-8 px-8'>
                                    <div className='bg-[#0D0D0D] rounded-xl p-5 flex items-center justify-center bg-opacity-40'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ffffff" className="w-6 h-6">
                                            <path fillRule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clipRule="evenodd" />
                                        </svg>
                                    </div>
                                    <div className='ps-4'>
                                        <h1 className='text-xs font-semibold text-primary-700'>Call Us Anytime</h1>
                                        <p className='text-base font-medium max-w-sm mt-1'>+1 305-503-5036</p>
                                    </div>
                                </div>
                                <div className='lg:block hidden m-auto border-r-[1px] h-8 border-[#363636]'></div>
                                <div className='flex items-center lg:px-8 px-8'>
                                    <div className='bg-[#0D0D0D] rounded-xl p-5 flex items-center justify-center bg-opacity-40'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ffffff" className="w-6 h-6">
                                            <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                            <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                                        </svg>
                                    </div>
                                    <div className='ps-4'>
                                        <h1 className='text-xs font-semibold text-primary-700'>Email</h1>
                                        <p className='text-base font-medium max-w-sm mt-1'>info@cvi.ai</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='relative z-20 lg:w-1/3 flex justify-end'>
                    <div className='relative lg:w-[26.75] w-full h-full shadow-md bg-[#1C1C1C] rounded-md flex flex-col gap-3 lg:p-12 p-8'>
                        <img src={PATH.img.DottedDesign} className='absolute top-0 left-0 w-full h-full object-cover' alt='dotted-design' />
                        <div className='relative z-10 flex flex-col gap-8'>
                            <div>
                                <p className='text-2xl font-medium'>Request a Demo</p>
                                <p className='text-sm font-medium mt-1'>Please fill the form to touch with you</p>
                            </div>
                            <div className='flex flex-col gap-8 mt-3'>
                                <Input
                                    placeholder={'Enter your name'}
                                    value={formDetails?.hasOwnProperty('name') ? formDetails?.name : ''}
                                    name={"name"}
                                    onChange={(event) => handleChange(event.target)}
                                    error={errors.name}
                                />
                                <Input
                                    value={formDetails?.hasOwnProperty('company') ? formDetails?.company : ''}
                                    placeholder={'Enter your company name'}
                                    name={"company"}
                                    onChange={(event) => handleChange(event.target)}
                                    error={errors.company}
                                />
                                <Input
                                    type={'number'}
                                    value={formDetails?.hasOwnProperty('phone') ? formDetails?.phone : ''}
                                    className='px-3 py-2 border border-gray-900 rounded-md'
                                    name={'phone'}
                                    error={errors.phone}
                                    onChange={(event) => handleChange(event.target)}
                                    onInput={(e) => handleNumberInput(e)}
                                    placeholder='Enter your phone number' />
                                {/* {errors?.phone && <p className='text-red-500 text-sm'>{errors.phone}</p>} */}
                                <Input
                                    className={'w-full lg:w-1/2 mt-5 lg:mt-0'}
                                    value={formDetails?.hasOwnProperty('email') ? formDetails?.email : ''}
                                    name={"email"}
                                    onChange={(event) => handleChange(event.target)}
                                    placeholder={'Enter your email'}
                                    error={errors.email}
                                />
                                <TextArea
                                    value={formDetails?.hasOwnProperty('message') ? formDetails?.message : ''}
                                    placeholder={'Enter your message'}
                                    name={"message"}
                                    onChange={(event) => handleChange(event.target)}
                                    error={errors.message}
                                />
                            </div>
                            <Button
                                value={'Submit'}
                                className={'border-[1px] border-white text-base mt-6 font-medium py-3'}
                                onClick={() => handleSubmitValidation()}
                            />
                            {emailSuccess && <div className='py-3 px-4 bg-[#00890026] rounded-md'>
                                <p className='text-sm'>Thank you! We will get back to you shortly.</p>
                            </div>}
                            {
                                emailError &&
                                <div className='py-3 px-4 bg-[#fe8a8a] rounded-md'>
                                    <p className='text-sm'>Opps! Something went wrong.</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Fundamentals;