import React from 'react';
import MediaIcons from '../MediaIcons';
import { PATH } from '../../globals/app-constants';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();

    return (
        <div className='text-white relative z-40 mb-0 bottom-0'>
            <div className='bg-[rgb(26,26,26)] p-6 flex justify-center gap-4'>
                <MediaIcons
                    ImgWidth={12}
                    IconPath={PATH.img.Facebook}
                />
                <MediaIcons
                    ImgWidth={14}
                    IconPath={PATH.img.Twitter}
                />
                <MediaIcons
                    ImgWidth={14}
                    IconPath={PATH.img.Instagram}
                />
                <MediaIcons
                    ImgWidth={14}
                    IconPath={PATH.img.Linkedin}
                />
            </div>
            <div className='bg-black px-5 lg:px-20 pt-14 pb-12 mb-0'>
                <div className='container mx-auto'>
                    <div className='lg:flex gap-4 container'>
                        <div className='lg:w-1/3'>
                            <img src={PATH.img.FooterLogo} alt='logo' className='w-20' />
                            <p className='mt-8 text-base text-white'>We are entering a new world of “Mixed Reality “– Live Commerce and Augmented reality will be intertwined to provide in-store experience sitting from home.</p>
                        </div>
                        <div className='lg:w-2/3 md:flex lg:mt-0 mt-12'>
                            <div className='md:w-1/2 flex justify-start lg:justify-center md:mt-0 mt-8'>
                                <div>
                                    <p className='text-2xl'>Service</p>
                                    <div className='w-6 border-t-[1px] border-white mt-2'></div>
                                    <ul className='list-none grid gap-4 mt-4'>
                                        <li><button ><span className='hover:underline text-base' onClick={() => navigate('/live-agent')}>Live Agent</span></button></li>
                                        <li><button><span className='hover:underline text-base' onClick={() => navigate('/augmented-reality')}>Augmented Reality</span></button></li>
                                        <li><button><span className='hover:underline text-base' onClick={() => navigate('/3d-view')}>3D View</span></button></li>
                                        <li><button><span className='hover:underline text-base' onClick={() => navigate('/cart-generation')}>Cart Generation</span></button></li>
                                        <li><button><span className='hover:underline text-base' onClick={() => navigate('/small-language')}>Small Language Model</span></button></li>
                                    </ul>
                                </div>
                            </div>
                            {/* <div className='md:w-1/3 flex justify-start lg:justify-center md:mt-0 mt-8'>
                                <div>
                                    <p className='text-2xl'>Resources</p>
                                    <div className='w-6 border-t-[1px] border-white mt-2'></div>
                                    <ul className='list-none grid gap-4 mt-4'>
                                        <li><button ><span className='hover:underline text-base'>Blog</span></button></li>
                                        <li><button><span className='hover:underline text-base'>Case Studies</span></button></li>
                                        <li><button><span className='hover:underline text-base'>Portfolio</span></button></li>
                                    </ul>
                                </div>
                            </div> */}
                            <div className='md:w-1/2 flex justify-start lg:justify-center md:mt-0 mt-8'>
                                <div>
                                    <p className='text-2xl'>Support</p>
                                    <div className='w-6 border-t-[1px] border-white mt-2'></div>
                                    <ul className='list-none grid gap-4 mt-4'>
                                        <li><button ><span className='hover:underline text-base' onClick={() => navigate('/contactus')}>Contact</span></button></li>
                                        <li><button><span className='hover:underline text-base' onClick={() => navigate('/privacy-policy')}>Privacy Policy</span></button></li>
                                        {/* <li><button><span className='hover:underline text-base'>Sitemap</span></button></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='border-t-[0.5px] border-gray-700 my-10'></div>
                    <div className='items-center w-full'>
                        <p className='capitalize text-sm font-normal text-center'>All Rights Reserved by CVI</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;