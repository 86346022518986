import React from "react";
import { PATH } from "../../../globals/app-constants";
import DetailExpand from "../../../component/Detail_Expand";
import RequestDemo from '../../../component/RequestDemo';
import Testimonial from '../../../component/Testimonial';
import Footer from '../../../component/HOC/Footer';

const View3d = () => {
    document.body.style.overflow = 'auto';
    return (
        <React.Fragment>
            <div className='lg:px-20 px-5 py-16'>
                <div className="container mx-auto">
                    <div className='flex lg:flex-row flex-col-reverse items-center mt-20'>
                        <div className='lg:w-2/5 lg:mt-0 mt-7'>
                            <img src={PATH.img.View3dDetail} alt='features-img' />
                        </div>
                        <div className='lg:w-3/5 lg:ps-7'>
                            <div className='flex flex-col gap-3'>
                                <p className='text-base'>CVI introduces 3D View</p>
                                <h6 className='text-[55px] flex items-center'><span className='lg:pe-5 pe-2'>3D View</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                                <p className='text-[22px]'>Dynamic 3D live view</p>
                                <p className='mt-2 text-base'>CVI introduces '3D View,' a versatile feature compatible with any web shopping platform. It offers a dual advantage: customers can effortlessly explore shopping items in a dynamic 3D live view from all angles with a simple click.</p>
                                <p className='mt-2 text-base'>Furthermore, if they desire, scanning the accompanying QR code allows them to visualize the product within their own space through their handheld device.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:px-20 bg-[#EFEFEF] px-5 py-3 lg:h-[500px] relative z-20 overflow-hidden">
                <div className='absolute lg:block hidden bottom-[-20px] border-gray-200 right-[-130px] top-0 w-60 h-60 border-[1px] rounded-full'></div>
                <div className='absolute lg:block hidden bottom-t-0 border-gray-200 right-60 top-0 w-80 border-[0.5px] rounded-full'></div>
                <div className="mx-auto relative overflow-x-auto py-20">
                    <div className='flex flex-col justify-center gap-2'>
                        <h6 className='text-2xl flex justify-center items-center'><div className='w-20 border-t-[1px] border-black'></div><span className='lg:px-5 px-2 text-center'>Why 3D View</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                        <p className="text-base max-w-3xl mx-auto text-center mt-3">Upgrade your online shopping adventure with 3D View, a groundbreaking feature that works on any web shopping site. It lets you spin products around like magic, and with a simple scan, you can bring them right into your world. Say goodbye to doubt and hello to the perfect choice. Dive into the future of online shopping with 3D View!</p>
                    </div>
                    <div className='grid md:grid-cols-4 gap-3 mt-10'>
                        <DetailExpand
                            HeadingDetail={'Competitive Advantage'}
                            DetailText={'Implementing 3D View on your web shopping platform sets you apart from the competition. It provides a unique and captivating shopping experience that can boost customer engagement and loyalty.'}
                        />
                        <DetailExpand
                            HeadingDetail={'Confidence in Purchase'}
                            DetailText={'Visualize how products fit and look in your own space before buying. This feature reduces uncertainty and returns, ensuring that customers choose the right items for their needs.'}
                        />
                        <DetailExpand
                            HeadingDetail={'Realistic Interior Design'}
                            DetailText={'For furniture and home decor shopping, customers can use 3D View to see how products fit and complement their existing interior design. Rearrange furniture virtually to create the perfect layout and ambiance.'}
                        />
                        <DetailExpand
                            HeadingDetail={'Personalized Virtual Wardrobe'}
                            DetailText={'Customers can curate and visualize their entire wardrobe virtually, experimenting with various clothing and accessory combinations for a tailored, stylish look. One at a time.'}
                        />
                    </div>
                </div>
            </div>
            {/* <div className='bg-[#EFEFEF] grid lg:grid-cols-3 gap-y-9 items-start lg:px-20 px-5 py-10'>
                <Fundamentals
                    Icon={PATH.img.ArIcon1}
                    className={'text-black'}
                    HeadingText={'Real-time Interaction'}
                    ParaText={'AR provides real-time interaction with virtual elements and information'}
                />
                <Fundamentals
                    Icon={PATH.img.ArIcon2}
                    className={'text-black'}
                    HeadingText={'Immersive Experience'}
                    ParaText={'Our product aims to create a sense of immersion and realism by combining virtual objects seamlessly with the real world'}
                />
                <Fundamentals
                    Icon={PATH.img.ArIcon3}
                    className={'text-black'}
                    HeadingText={'Enhanced Visualization'}
                    ParaText={'AR can be used in various industries to visualize complex data, such as 3D models, architectural plans'}
                />
            </div>
            <div className="lg:flex items-center lg:px-20 px-5 py-10 relative overflow-hidden">
                <div className="lg:w-1/2">
                    <div className="py-10 lg:pe-10 relative">
                        <img src={PATH.img.AboutDesign} className="absolute top-0 right-36 w-44" />
                        <video loop muted playsInline autoPlay className="relative z-10 w-full lg:h-full rounded-md object-cover">
                            <source src={PATH.video.Video3d} type="video/mp4" />
                        </video>
                    </div>
                </div>
                <div className='lg:w-1/2 flex flex-col gap-3 lg:ps-8 z-20'>
                    <div className='absolute lg:block hidden bottom-[-20px] border-gray-200 right-[-110px] top-16 w-60 h-60 z-10 border-[1px] rounded-full'></div>
                    <div className='absolute lg:block hidden bottom-t-0 border-gray-200 right-60 top-0 w-80 border-[0.5px] rounded-full rotate-90'></div>
                    <div className='absolute lg:block hidden bottom-t-0 border-gray-200 right-[27rem] bottom-0 w-80 border-[0.5px] rounded-full rotate-90'></div>
                    <p className='text-base'>The resources and capabilities that comprise the strategic advantages of a business.</p>
                    <p className='text-lg'>Get a better glimpse of AR in</p>
                    <h6 className='text-4xl flex items-center'><span className='lg:pe-5 pe-2'>our product</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                    <div className="lg:ps-16 mt-5">
                        <p className='text-base'>Our only focus is to build solution for Ecommerce, Retail, Food, Beverages, Hospitality & QSR market.</p>
                        <p className='mt-3 text-base'>5+ Product IP (Intellectual Property) owned by Computer Vision Inc having its operation in USA.</p>
                        <p className='mt-3 text-base'>We are in software development space for more than 10 years.</p>
                        <p className='mt-3 text-base'>CV Innovation Lab in Austin(USA) and Chennai(India)</p>
                    </div>
                </div>
            </div>
            <div className="lg:px-20 px-5 py-3 bg-[#EFEFEF] lg:h-[500px]">
                <div className="container mx-auto relative overflow-x-auto py-20">
                    <div className='flex flex-col justify-center gap-4'>
                        <h6 className='text-[32px] flex justify-center items-center'><div className='w-20 border-t-[1px] border-black'></div><span className='lg:px-5 px-2'>Benefits</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                        <p className='text-[22px] text-center'>Realism and Immersion</p>
                        <p className="text-base max-w-3xl mx-auto text-center mt-3"> 3D view allows digital objects to be rendered in three dimensions, making them appear more realistic and integrated into the real world. This enhances the user's sense of immersion in the AR environment.</p>
                    </div>
                    <div className='grid md:grid-cols-4 gap-3 mt-10'>
                        <DetailExpand
                            HeadingDetail={'Spatial Understanding'}
                            DetailText={'3D view helps users perceive the spatial relationships between virtual objects and the physical world.'}
                        />
                        <DetailExpand
                            HeadingDetail={'Object Interaction'}
                            DetailText={'3D view enables more natural and intuitive interaction with virtual objects.'}
                        />
                        <DetailExpand
                            HeadingDetail={'Wayfinding and Navigation'}
                            DetailText={'3D view assists users in getting directions and navigating their surroundings.'}
                        />
                        <DetailExpand
                            HeadingDetail={'Remote Collaboration'}
                            DetailText={'3D view in AR facilitates remote collaboration by allowing multiple users to view and interact with the same 3D content simultaneously.'}
                        />
                    </div>
                </div>
            </div>
            <div className="lg:flex items-center lg:px-20 px-5 py-16">
                <div className="lg:w-1/2">
                    <div className="flex flex-col gap-3">
                        <p className='text-base'>A Blend Of Physical And Digital World</p>
                        <p className='text-lg'>Real Time View</p>
                        <h6 className='text-4xl flex items-center'><span className='lg:pe-5 pe-2'>Coming Soon</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                        <img src={PATH.img.ComingSoonImg} className="p-10" />
                    </div>
                </div>
                <div className='lg:w-1/2 flex flex-col ps-8'>
                    <DetailUpcoming
                        HeadingDetail={'Improved Hardware'}
                        DetailText={' Expect advancements in AR headset and glasses technology, offering better comfort, wider fields of view, and enhanced displays for a more immersive experience.'}
                    />
                    <DetailUpcoming
                        HeadingDetail={'5G Integration'}
                        DetailText={'As 5G networks become more widespread, AR applications are likely to benefit from faster and more stable data connections, reducing latency and improving real-time experiences.'}
                    />
                    <DetailUpcoming
                        HeadingDetail={'Object Recognition and Tracking'}
                        DetailText={'AR systems will become more adept at recognizing and tracking a broader range of real-world objects, enabling richer and more interactive AR experiences.'}
                    />
                    <DetailUpcoming
                        HeadingDetail={'Extended Reality (XR)'}
                        DetailText={'The blending of AR with virtual reality (VR) and mixed reality (MR) will become more common, offering a continuum of experiences in a single device.'}
                    />
                </div>
            </div> */}
            <RequestDemo
                RequesDemoHeading={'Shop with Confidence'}
                RequestDemoMainHead={'Experience 3D View Today'}
                RequestDemoPara={`Welcome to the future of online shopping with our revolutionary 3D View feature. Visualize products from every angle and experience their true dimensions as if you were holding them in your hands. Try on clothing, place furniture in your living space, and make confident purchases with 3D View. It's time to shop smarter, with greater confidence, and set your online store apart from the rest.`}
                RequestDemoPara2={'Explore our 3D View feature now and experience a new level of immersion and engagement in your shopping journey.'} />
            <Testimonial />
            <Footer />
        </React.Fragment >
    );
};

export default View3d;