import React from "react";

const DetailUpcoming = ({ HeadingDetail, DetailText }) => {
    return (
        <React.Fragment>
            <div className='group border-l-[0.5px] border-black ps-10 pe-8 pb-10 last:pb-0 relative last:border-l-0 cursor-default'>
                <p className='text-xl font-medium transition-all ease-in duration-300'>{HeadingDetail}</p>
                <p className="text-base mt-2 transition-all ease-in duration-300">{DetailText}</p>
                <div className="absolute top-0 left-[-14px] w-7 h-7 rounded-full border-[1px] border-black bg-white flex items-center justify-center group-hover:left-[-18px] group-hover:w-9 group-hover:h-9 transition-all ease-in duration-300">
                    <div className="w-3 h-3 rounded-full bg-black group-hover:w-5 group-hover:h-5 transition-all ease-in duration-300"></div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default DetailUpcoming