import React, { useState, useEffect } from "react";
import { PATH } from "../../globals/app-constants";
import SideMenu from "../SideMenu";
import { Link, useNavigate } from "react-router-dom";

const Header = (props) => {
    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate();
    const handleMenu = () => {
        setShowMenu(!showMenu);
    };

    // console.log(props?.isHome, 'isJHOwm')

    useEffect(() => {
        if (window.location.pathname === "/") {
            // setIsHome(true)
        }
    }, []);
    return (
        <div>
            <div
                className={`z-40 top-0 px-5 lg:px-30 w-full fixed transition-all duration-500 text-sm ${props?.isHome && window.location.pathname === "/" ? "bg-transparent text-white header-gradient" : "bg-white text-black shadow"
                    } `}>
                <div className="container mx-auto">
                    <div className={`pt-7 relative flex items-center justify-between z-10 ${props?.isHome && window.location.pathname === "/" ? "pb-24" : "py-7"}`}>
                        <div onClick={handleMenu}>
                            <p className="flex cursor-pointer">
                                <img className="me-2" alt="menu-icon" src={props?.isHome && window.location.pathname === "/" ? PATH.img.Menu : PATH.img.MenuBlack} />
                                <span className="text-base">Menu</span>
                            </p>
                        </div>
                        <img
                            alt="logo"
                            className="absolute lg:left-0 lg:right-0 right-0 mx-auto block cursor-pointer"
                            width={45}
                            onClick={() => navigate("/")}
                            src={props?.isHome && window.location.pathname === "/" ? PATH.img.LogoWhite : PATH.img.LogoBlack}
                        />
                        <div className="lg:flex hidden">
                            {/* <p className='me-8 cursor-pointer text-base'>Create Account</p> */}
                            <Link to={"https://meet.h4helpnow.com/vendor"} target="_blank" className="flex align-middle">
                                <span className="text-base me-2">Login</span>
                                <img alt="login-icon" src={props?.isHome && window.location.pathname === "/" ? PATH.img.LoginIcon : PATH.img.LoginBlack} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {showMenu && <SideMenu showMenu={showMenu} setShowMenu={setShowMenu} />}
        </div>
    );
};

export default Header;
