import React from "react";

const SideBarIcons = ({ MediaName, IconPath }) => {
    return (
        <div className="flex items-center">
            <div className='w-9 h-9 bg-[#323131] rounded-full flex items-center justify-center'>
                <img src={IconPath} width={12} alt='facebook' />
            </div>
            <p className='ms-2'>{MediaName}</p>
        </div >
    )
}

export default SideBarIcons
