import React from "react";
import Footer from "../../component/HOC/Footer";

const PrivacyPolicy = () => {
    document.body.style.overflow = 'auto';
    return (
        <React.Fragment>
            <div className='lg:px-20 px-5 lg:py-14 py-10 relative'>
                <div className="container mx-auto">
                    <h6 className='text-[32px] flex justify-center items-center mt-10'><div className='w-20 border-t-[1px] border-black'></div><span className='lg:px-5 px-2 text-center'>Privacy Policy</span><div className='w-20 border-t-[1px] border-black'></div></h6>
                    <div>
                        <p className="text-2xl font-medium mt-4">Policy</p>
                        <p className="text-base mt-2">In compliance with the relevant laws, contractual commitments, and industry
                            best practices, CVI is dedicated to safeguarding the confidentiality,
                            integrity, and accessibility of personal data accessed, processed, or stored as
                            part of company operations.</p>
                        <p className="text-base mt-2">A person in charge of CVI's program for protecting individual privacy, such
                            as a data protection officer or privacy officer who directly answers to the
                            highest level of management in the company (such as a CTO), must be
                            appointed. The choice of an appointee will be based on their qualifications as
                            professionals, their expertise in data protection law and procedures, and their
                            capacity to carry out their assigned duties.</p>
                        <p className="text-xl mt-4 font-medium">Among the responsibilities are:
                        </p>
                        <p className="text-base mt-2">1. Creating and implementing privacy rules and procedures</p>
                        <p className="text-base mt-2">2. Providing managers, users, and service providers with privacy-related
                            guidance on their particular obligations and the processes that are
                            followed. Acting as the point of contact for all privacy-related matters,
                            including the receipt of complaints connected to privacy.</p>
                        <p className="text-base mt-2">3. Considering the type, scope, context, and aims of processing, while also
                            taking into account the risk involved with processing processes.</p>
                        <p className="text-base mt-2">4. CVI must provide the resources and access to personal data and
                            processing activities necessary for the data protection officer to carry
                            out the duties imposed by law or regulation</p>
                        <p className="text-base mt-2">5. According to the relevant legislation or regulation, CVI should make
                            sure that the data protection or privacy officer is not given any
                            instructions regarding the execution of such responsibilities and is
                            subject to a confidentiality or secrecy obligation with relation to those
                            tasks.</p>

                        <p className="text-2xl mt-8 font-medium">Policy Principles</p>
                        <p className="text-base mt-2">Personal data collection should be avoided wherever possible and restricted to
                            situations with a strong business purpose.</p>
                        <p className="text-base mt-2">An suitable, plain-language notice of the organization's possible uses and
                            disclosures of the individuals' personal data must be given to the people whose
                            personal data is being collected by the organization. The business must follow
                            the guidelines in its privacy notice to the data subject while using and
                            disclosing PHI.</p>
                        <p className="text-base mt-2">The appropriate administrative, physical, and technical safeguards must be
                            used by function and business units that collect and handle personal
                            information to protect the personally identifiable information they maintain or
                            disseminate against unauthorized access and unauthorized use</p>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
};

export default PrivacyPolicy;