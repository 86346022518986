import React, { useState, useRef, useEffect } from 'react';
import { PATH } from '../globals/app-constants.js';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const CompareSlider = ({ className, ScrollTo }) => {
    const [value, setValue] = useState(50);
    const [finalWidth, setFinalWidth] = useState();
    const [viewport, setViewport] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const vidRef = useRef(null);
    const containerRef = useRef();

    const sliderWidthHandler = (portWidth, range) => {
        setFinalWidth((portWidth * range) / 100);
    };

    const handleInputChange = (event) => {
        sliderWidthHandler(viewport.width, event.target.value);
        console.log(finalWidth);
        const inputValue = event.target.value;
        setValue(parseInt(inputValue));
        event.target.parentNode.style.setProperty('--value', `${value}%`);
    };
    console.log(finalWidth);
    useEffect(() => {
        sliderWidthHandler(viewport.width, value);
    }, []);

    return (
        <div id='slider-section'
            className={`${className}`}
            ref={containerRef}
        // onWheel={handleScrollMouse}
        >
            <div className='w-full absolute z-40 text-white top-1/3'>
                <div className='container gap-8 px-5 md:w-1/2 flex flex-col justify-center items-center'>
                    <h6 className='text-2xl md:text-5xl font-medium text-center'>Revolutionizing Commerce: Say Goodbye to Boredom and Search Struggles</h6>
                    <div className='relative w-full'>
                        <Carousel
                            autoPlay
                            stopOnHover={false}
                            showThumbs={false}
                            showArrows={false}
                            infiniteLoop={true}
                            showIndicators={false}
                            showStatus={false}
                            className='relative container top-0 h-24'>
                            <div>
                                <p className='md:text-lg text-center w-full px-5'>Step into new ways of generating revenue and customer intimacy ....</p>
                            </div>
                            <div>
                                <p className='md:text-lg text-center w-full px-5'>We combine : Small Language model, speech to text to search , 3D and Augmented reaality </p>
                            </div>
                            <div>
                                <p className='md:text-lg text-center w-full px-5'>This drives Hyper personalized e commerce which is what a customer expects in physical store Brand amabassadors</p>
                            </div>
                        </Carousel>
                    </div>
                    <button className='w-10 h-10 flex justify-center items-center border-2 rounded-full' onClick={ScrollTo}>
                        <ChevronDownIcon width={16} />
                    </button>
                    {/* <a href={'#request-demo'} className={'bg-white w-auto text-black rounded-md px-4 py-2 '}>Reed More</a> */}
                </div>
            </div>
            <div
                className='flex c-compare'
                style={{ '--value': `${value}%`, transition: "--value 500ms ease-in-out", height: '100%' }}
            >
                <div>
                    <div style={{ width: `${finalWidth}px` }}>
                        <video loop muted playsInline autoPlay={true} className="object-cover w-full h-screen">
                            <source src={PATH.video.OLD_COMPARE} type="video/mp4" />
                        </video>
                    </div>
                </div>
                <div>
                    <video loop muted playsInline autoPlay={true} className="object-cover w-full h-screen relative">
                        <source src={PATH.video.NEW_COMPARE} type="video/mp4" />
                    </video>
                </div>
                <input
                    type="range"
                    className="c-rng c-compare__range"
                    min="0"
                    max="100"
                    value={value}
                    onChange={handleInputChange}
                />
            </div>
        </div>
    );
};

export default CompareSlider;